import { FC } from "react";
import { StatsWrapper } from "./StatsWrapper.tsx";
import { StatsItem } from "./StatsItem.tsx";
import {
  incomeFromClientsSelector,
  incomeFromTeamSelector,
  totalProfitSelector,
} from "../../../selectors/managerInfoSelectors.ts";
import { useAppSelector } from "../../../hooks/storeHooks.ts";

type PropsType = {};
export const Income: FC<PropsType> = () => {
  const totalProfit = useAppSelector(totalProfitSelector);
  const incomeFromClients = useAppSelector(incomeFromClientsSelector);
  const incomeFromTeam = useAppSelector(incomeFromTeamSelector);

  return (
    <StatsWrapper>
      <StatsItem
        title={"Заработок"}
        value={`${totalProfit}$`}
        cashValue
        headingTitle
      />
      <StatsItem title={"Клиенты"} value={`${incomeFromClients}$`} cashValue />
      <StatsItem title={"Команда"} value={`${incomeFromTeam}$`} cashValue />
    </StatsWrapper>
  );
};
