import { RootState } from "../bll/store/store.ts";

const clientsPagesCountSelector = (state: RootState) =>
  state.salesPublicManager.clients.pagesCount;
const getClientsSelector = (state: RootState) =>
  state.salesPublicManager.clients.items;
const teamPagesCountSelector = (state: RootState) =>
  state.salesPublicManager.team.pagesCount;
const getTeamSelector = (state: RootState) =>
  state.salesPublicManager.team.items;
const isFetchingSelector = (state: RootState) =>
  state.salesPublicManager.isFetching;

export {
  clientsPagesCountSelector,
  getClientsSelector,
  teamPagesCountSelector,
  getTeamSelector,
  isFetchingSelector,
};
