import { Icon } from "../../icons/IconWrapper.tsx";
import { ICON } from "../../icons/components/Types.ts";
import st from "./IconClose.module.scss";
import { ICON_SIZE_TWENTY_FOUR } from "../../../common/const/constants-ui-kit.ts";

type SizeIconClose = "big" | "small";

type PropsType = {
  sizeIconClose: SizeIconClose;
  handleOnClickIconClose: () => void;
  className?: string;
};
export const IconClose = ({
  sizeIconClose,
  handleOnClickIconClose,
  className,
}: PropsType) => {
  return (
    <div
      className={`${st.block} ${sizeIconClose === "big" ? st.blockBig : st.blockSmall} ${className}`}
      onClick={handleOnClickIconClose}
    >
      <Icon
        className={st.icon}
        iconId={ICON.CLOSE2}
        color={"var(--color-background-1)"}
        height={ICON_SIZE_TWENTY_FOUR}
        width={ICON_SIZE_TWENTY_FOUR}
      />
    </div>
  );
};
