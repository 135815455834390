import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ChangePasswordParams,
  ManagerInfoType,
  salesAuthApi,
  SalesLoginParams,
  SalesRegistrationParams,
} from "../../API/apiSalesAuth.ts";

import { clearStateAfterLogout } from "../../common/actions/clearStateAfterLogout.ts";
import {
  removeTokenInfoFromLS,
  setTokenInfoToLS,
  TOKENS,
} from "../../common/utils/tokenHelpers.ts";
import { thunkErrorHandler } from "../../common/utils/thunkErrorHandler.ts";
import { setAppSuccessMessage } from "./appReducer.ts";
import { RootState } from "../store/store.ts";

const slice = createSlice({
  name: "sales/auth",
  initialState: {
    isAuthorized: false,
    isInitialized: false,
    managerProfile: {} as ManagerInfoType,
    inviterId: 0,
  },
  reducers: {
    setInviterIdAC: (state, action: PayloadAction<number>) => {
      state.inviterId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.fulfilled, (state, action) => {
        state.managerProfile = action.payload.managerProfile;
        state.isAuthorized = true;
        state.isInitialized = true;
      })
      .addCase(getMe.rejected, (state) => {
        state.isInitialized = true;
      })
      .addCase(clearStateAfterLogout, (state) => {
        state.isAuthorized = false;
        state.managerProfile = {} as ManagerInfoType;
      });
  },
});

const login = createAsyncThunk<void, SalesLoginParams>(
  "sales/auth/login",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await salesAuthApi.login(data);
      setTokenInfoToLS(
        res.data.accessToken,
        TOKENS.SALES,
        TOKENS.SALES_EXP_TIME,
      );
      dispatch(salesAuthThunks.getMe());
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  },
);

const logout = createAsyncThunk<void>(
  "sales/auth/logout",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await salesAuthApi.logout();
      removeTokenInfoFromLS(TOKENS.SALES, TOKENS.SALES_EXP_TIME);
      dispatch(clearStateAfterLogout());
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue("null");
    }
  },
);

const registration = createAsyncThunk<void, SalesRegistrationParams>(
  "sales/auth/registration",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const {
        salesAuth: { inviterId },
      } = getState() as RootState;

      await salesAuthApi.registration({ ...data, inviterId });
      dispatch(
        setAppSuccessMessage({
          successMessage: "Вы зарегистрировались! Войдите в кабинет.",
        }),
      );
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  },
);

const resetPassword = createAsyncThunk<void, { email: string }>(
  "sales/auth/resetPassword",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      await salesAuthApi.resetPassword(data);
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  },
);
const changePassword = createAsyncThunk<void, ChangePasswordParams>(
  "sales/auth/changePassword",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      await salesAuthApi.changePassword(data);
      dispatch(
        setAppSuccessMessage({
          successMessage: "Пароль изменен! Войдите в кабинет.",
        }),
      );
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  },
);

const getMe = createAsyncThunk<{ managerProfile: ManagerInfoType }>(
  "sales/auth/me",
  async (_, { rejectWithValue }) => {
    try {
      const res = await salesAuthApi.getMe();
      return { managerProfile: res.data };
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const salesPending = isPending(
  changePassword,
  login,
  logout,
  registration,
  resetPassword,
);
export const salesFulfilled = isFulfilled(
  changePassword,
  login,
  logout,
  registration,
  resetPassword,
);
export const salesRejected = isRejected(
  changePassword,
  login,
  logout,
  registration,
  resetPassword,
);

export const salesAuthReducer = slice.reducer;
export const salesAuthThunks = {
  login,
  logout,
  registration,
  resetPassword,
  changePassword,
  getMe,
};
export const { setInviterIdAC } = slice.actions;
