import * as React from "react";

import SpriteIcons from "../../assets/svg/sprite/sprite-icons.svg";

export type IconProps = {
  color?: string;
  viewBox?: string;
  iconId?: string;
  className?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
};

export const Icon: React.FC<IconProps> = ({
  color: colorProp,
  width,
  height,
  viewBox,
  iconId,
  className,
  onClick,
}) => {
  const color = colorProp ? colorProp : "var(--color-background-1)";

  return (
    <svg
      role="icon"
      width={width || "24px"}
      height={height || "24px"}
      viewBox={viewBox || "0 0 24 24"}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <use xlinkHref={`${SpriteIcons}#${iconId}`} />
    </svg>
  );
};
