import { MyClientsCount } from "../../../common/components/managerStats/MyClientsCount.tsx";
import { Income } from "../../../common/components/managerStats/Income.tsx";
import { TeamCount } from "../../../common/components/managerStats/TeamCount.tsx";
import s from "./ManagerStatsBlock.module.scss";

export const ManagerStatsBlock = () => {
  return (
    <div className={s.statsBlockWrapper}>
      <MyClientsCount />
      <Income />
      <TeamCount />
    </div>
  );
};
