import { FC } from "react";
import { SalesPagination } from "../../../../common/components/pagination/Pagination.tsx";
import { MyPaymentsTableLayout } from "./MyPaymentsTableLayout.tsx";
import { MyPaymentsTableRow } from "./MyPaymentsTableRow.tsx";
import {
  GetHistoryResponseType,
  HistoryItemResponseType,
} from "../../salesBalanceApi.ts";
import { CircularProgress } from "@mui/material";
import { isSalesBalanceHistoryFetchingSelector } from "../../salesBalanceSelectors.ts";
import { useAppSelector } from "../../../../hooks/storeHooks.ts";
import s from "./MyPaymentsTable.module.scss";

type Props = {
  historyData: GetHistoryResponseType;
  currentPage: string;
  onChangePage: (page: string) => void;
};

const historyItems: HistoryItemResponseType[] = [
  {
    id: 1,
    managerId: 1,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer1@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
];

export const MyPaymentsTable: FC<Props> = ({
  historyData,
  currentPage,
  onChangePage,
}) => {
  const isSalesBalanceHistoryFetching = useAppSelector(
    isSalesBalanceHistoryFetchingSelector,
  );

  return (
    <div className={s.noHistoryTextWrapper}>
      {isSalesBalanceHistoryFetching && (
        <div className={s.circularProgress}>
          <CircularProgress />
        </div>
      )}
      {!isSalesBalanceHistoryFetching && historyData.items.length === 0 ? (
        <>
          <div className={s.noHistoryText}>Нет истории транзакций</div>
          <div className={s.emptyTable}>
            <MyPaymentsTableLayout>
              {historyItems.map((i) => {
                return <MyPaymentsTableRow key={i.id} historyItem={i} />;
              })}
            </MyPaymentsTableLayout>
          </div>
        </>
      ) : (
        <div>
          <MyPaymentsTableLayout>
            {historyData.items?.map((i) => {
              return <MyPaymentsTableRow key={i.id} historyItem={i} />;
            })}
          </MyPaymentsTableLayout>
          <SalesPagination
            page={currentPage}
            pagesCount={historyData.pagesCount}
            onChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
};
