import { RootState } from "../bll/store/store.ts";

const isAuthorizedSelector = (state: RootState) => state.salesAuth.isAuthorized;
const isInitializedSelector = (state: RootState) =>
  state.salesAuth.isInitialized;
const managerProfileDataSelector = (state: RootState) =>
  state.salesAuth.managerProfile;
const managerPromoCodeSelector = (state: RootState) =>
  state.salesAuth.managerProfile.promo;

export {
  isAuthorizedSelector,
  isInitializedSelector,
  managerProfileDataSelector,
  managerPromoCodeSelector,
};
