import { CSSProperties, FC, ReactNode } from "react";
import { Component, Typography } from "../typography";
import s from "./Card.module.scss";
import clsx from "clsx";

type cardNameType = "pluses" | "aboutUs" | "howWork";
export type CardProps = {
  title: string;
  desc: string | ReactNode;
  img?: ReactNode;
  dark?: boolean;
  cardName: cardNameType;
};
export const Card: FC<CardProps> = ({
  title,
  img,
  desc,
  dark = false,
  cardName,
}) => {
  let titleTypographyVariant: Component = "title_any_cads";
  let descTypographyVariant: Component = "typeface_main";
  let align: CSSProperties["textAlign"] = "center";
  let isDarkTypography = true;
  if (cardName === "aboutUs") {
    titleTypographyVariant = "title_secondary";
    descTypographyVariant = "subheader_secondary";
    align = "start";
    isDarkTypography = !dark;
  }

  return (
    <div className={clsx(s.root, dark && s.dark, s[cardName])}>
      <div className={s.img}>{img}</div>
      <Typography
        variant={titleTypographyVariant}
        dark={isDarkTypography}
        align={align}
        className={clsx(cardName === "aboutUs" && s.title)}
      >
        {title}
      </Typography>
      <Typography
        variant={descTypographyVariant}
        dark={isDarkTypography}
        align={align}
      >
        {desc}
      </Typography>
    </div>
  );
};
