import { Section } from "../Section.tsx";
import { faqItems } from "./faqContent.tsx";
import { Green, Typography } from "../../../typography";
import s from "./Faq.module.scss";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../accordion/Accordion.tsx";

export const Faq = () => {
  return (
    <Section dark className={s.faq} id={"faq"}>
      <div className={"container"}>
        <Typography variant={"title_block"} align={"end"} className={s.offset}>
          <Green>FAQ</Green>
        </Typography>
        <Accordion>
          {faqItems.map((el, index) => {
            return (
              <AccordionItem value={`item-${index + 1}`} key={index}>
                <AccordionTrigger number={index + 1}>
                  {el.title}
                </AccordionTrigger>
                <AccordionContent button={el.button}>
                  {el.content}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </Section>
  );
};
