import { useState } from "react";
import s from "./WithdrawalsAction.module.scss";
import { useFormik } from "formik";
import { FormikValuesType } from "../../../../common/types/commonTypes.ts";
import * as Yup from "yup";
import { usePasswordVisible } from "../../../../hooks/usePasswordVisible.ts";
import { ActionBlock } from "./actionBlock/ActionBlock.tsx";
import { PasswordBlock } from "./passwordBlock/PasswordBlock.tsx";
import { SuccessBlock } from "./successBlock/SuccessBlock.tsx";
import { AccordionBlock } from "./accordionBlock/AccordionBlock.tsx";
import { salesBalanceThunks } from "../../salesBalanceSlice.ts";
import { useAppDispatch } from "../../../../hooks/storeHooks.ts";
import { handleServerNetworkError } from "../../../../common/utils/error-utils.ts";

export type ActiveWindowType = "withdrawals" | "password" | "success";

export const WithdrawalsAction = () => {
  const dispatch = useAppDispatch();
  const { visibility, toggleVisibility } = usePasswordVisible();
  const [activeWindow, setActiveWindow] =
    useState<ActiveWindowType>("withdrawals");

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Пожалуйста, введите пароль")
        .min(6, "Минимум 6 символов!"),
    }),
    onSubmit: async (values) => {
      if (values.password) {
        await dispatch(
          salesBalanceThunks.checkPassword(values.password.toString()),
        )
          .unwrap()
          .then(() => {
            setActiveWindow("success");
          })
          .catch((error) => {
            if (error.response.data.statusCode === 403) {
              handleServerNetworkError(
                "Запрос на вывод средств уже получен или баланс равен 0",
                dispatch,
              );
            }
            if (error.response.data.statusCode === 400) {
              handleServerNetworkError("Неправильный пароль", dispatch);
            }
          });
      }
      formik.resetForm();
    },
  });

  return (
    <div className={s.withdrawalsWrapper}>
      {activeWindow === "withdrawals" && (
        <ActionBlock setActiveWindow={setActiveWindow} />
      )}
      {activeWindow === "password" && (
        <PasswordBlock
          formik={formik}
          visibility={visibility}
          toggleVisibility={toggleVisibility}
          setActiveWindow={setActiveWindow}
        />
      )}
      {activeWindow === "success" && <SuccessBlock />}
      <div>
        <AccordionBlock />
      </div>
    </div>
  );
};
