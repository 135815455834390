import { FC, PropsWithChildren, ReactNode } from "react";
import { Typography } from "../../typography";
import clsx from "clsx";
import s from "./Terms.module.scss";

type SectionType = {
  number: string;
  children: ReactNode;
  title: string;
};

export const Section: FC<SectionType> = ({ number, children, title }) => {
  return (
    <table className={s.tableSection}>
      <thead>
        <tr>
          <th className={s.sectionNumber}>
            <Typography
              variant={"title_primary_1"}
              align={"start"}
              dark
              className={clsx(s.title)}
            >
              {number}.
            </Typography>
          </th>
          <th className={s.sectionTitle}>
            <Typography
              variant={"title_primary_1"}
              align={"start"}
              dark
              className={s.title}
            >
              {title}
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

type DescType = {
  desc?: string;
  children: ReactNode[] | ReactNode;
};
export const Desc: FC<DescType> = ({ children }) => {
  const isArray = Array.isArray(children);
  return (
    <tr>
      <td></td>
      <td className={clsx(isArray && s.tdWithTable)}>
        <Typography
          variant={"typeface_main"}
          className={s.desc}
          dark
          align={"start"}
        >
          {!isArray && typeof children === "string" && children}
          {isArray && children[0]}
        </Typography>
        {!isArray && typeof children !== "string" && children}
        {isArray && children[1] && children[1]}
      </td>
    </tr>
  );
};

type PointType = {
  number: string;
  children?: ReactNode;
  title?: string;
};
export const Points: FC<PropsWithChildren> = ({ children }) => {
  return (
    <table>
      <tbody className={s.bodyPoint}>{children}</tbody>
    </table>
  );
};
export const Point: FC<PointType> = ({ number, children, title }) => {
  const isArray = Array.isArray(children);
  return (
    <tr>
      <td>
        <Typography
          variant={"subheader_secondary"}
          align={"start"}
          bold
          className={s.pointNumber}
        >
          {number}.
        </Typography>
      </td>
      <td className={s.tdsecond}>
        <Typography
          variant={"subheader_secondary"}
          align={"start"}
          bold
          className={s.pointTitle}
          dark
        >
          {title}
          {!isArray && children}
          {isArray && children[0]}
        </Typography>
        {isArray && children[1] && children[1]}
      </td>
    </tr>
  );
};
