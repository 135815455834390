import { configureStore } from "@reduxjs/toolkit";
import { salesAuthReducer } from "../reducers/salesAuthSlice.ts";
import { salesBalanceReducer } from "../../components/salesBalance/salesBalanceSlice.ts";
import { salesSettingsReducer } from "../../components/salesSettings/salesSettingsSlice.ts";
import { salesPublicManagerReducer } from "../reducers/publicManagerSlice.ts";
import { thunk } from "redux-thunk";

export const store = configureStore({
  reducer: {
    salesAuth: salesAuthReducer,
    salesPublicManager: salesPublicManagerReducer,
    salesBalance: salesBalanceReducer,
    salesSettings: salesSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

