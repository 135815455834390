import { useNavigate } from "react-router-dom";
import { getClientsSelector } from "../../../selectors/managerPublicSelectors.ts";
import { Clients } from "../../../common/components/clients/Clients.tsx";
import { useAppSelector } from "../../../hooks/storeHooks.ts";
import { PATH } from "../../../common/path/path.ts";

export const ManagerClients = () => {
  const navigate = useNavigate();
  const clients = useAppSelector(getClientsSelector);
  const toAllClients = () => {
    navigate(`/sales/${PATH.salesAllClients}`);
  };

  return (
    <Clients clients={clients} btnAction={toAllClients} variant={"sales"} />
  );
};
