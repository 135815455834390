import { StatsWrapper } from "./StatsWrapper.tsx";
import { StatsItem } from "./StatsItem.tsx";
import {
  freeClientsSelector,
  paidClientsSelector,
  totalClientsSelector,
} from "../../../selectors/managerInfoSelectors.ts";
import { useAppSelector } from "../../../hooks/storeHooks.ts";

export const MyClientsCount = () => {
  const totalClientsCount = useAppSelector(totalClientsSelector);
  const paidClientsCount = useAppSelector(paidClientsSelector);
  const freeClientsCount = useAppSelector(freeClientsSelector);

  return (
    <StatsWrapper>
      <StatsItem title={"Мои клиенты"} value={totalClientsCount} headingTitle />
      <StatsItem title={"Платные"} value={paidClientsCount} />
      <StatsItem title={"Бесплатные"} value={freeClientsCount} />
    </StatsWrapper>
  );
};
