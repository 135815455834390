import { FC } from "react";
import { Typography } from "../../typography";
import clsx from "clsx";
import { Icon } from "../../icons/IconWrapper.tsx";
import { ICON } from "../../icons/components/Types.ts";
import s from "./UserControl.module.scss";

type UserControlType = {
  name: string;
  photo?: string;
  dark?: boolean;
};
export const UserControl: FC<UserControlType> = ({ name, photo, dark }) => {
  return (
    <div className={clsx(s.userControl, dark && s.dark)}>
      <div className={s.userPhoto}>
        {photo ? (
          <img src={photo} alt="" />
        ) : (
          <Icon iconId={ICON.USER} width={"20px"} height={"20px"} />
        )}
      </div>

      <Typography variant={"btn_s"} className={s.name}>
        {name}
      </Typography>
      <Icon iconId={ICON.ARROW_DOWN} width={"20px"} height={"20px"} />
    </div>
  );
};

type NotificationsType = {
  isActive: boolean;
  dark?: boolean;
};
export const Notifications: FC<NotificationsType> = ({ isActive, dark }) => {
  return (
    <div className={clsx(s.notification, dark && s.dark)}>
      <div className={s.icon}>
        <Icon iconId={ICON.NOTIFICATION} width={"18px"} height={"18px"} />
        {isActive && <div className={s.signal}></div>}
      </div>
    </div>
  );
};
