import { useFormik } from "formik";
import { FormikValuesType } from "../../../common/types/commonTypes.ts";
import * as Yup from "yup";
import { CustomForm } from "../../customForm/CustomForm.tsx";
import { PasswordInput } from "../../passwordInput/PasswordInput.tsx";
import { usePasswordVisible } from "../../../hooks/usePasswordVisible.ts";
import { salesAuthThunks } from "../../../bll/reducers/salesAuthSlice.ts";
import { ChangePasswordParams } from "../../../API/apiSalesAuth.ts";
import { useAppDispatch } from "../../../hooks/storeHooks.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../../../common/path/path.ts";

export const SetNewPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { visibility, toggleVisibility } = usePasswordVisible();

  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      codeFromEmail: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Пожалуйста, введите пароль")
        .min(8, "Пароль должен быть больше 7 символов"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Пароли не совпадают")
        .required("Поле обязательно"),
    }),
    onSubmit: (values) => {
      if (values.password && values.confirmPassword) {
        const params: ChangePasswordParams = {
          password: values.password,
          password_confirm: values.confirmPassword,
          code: code!,
        };
        dispatch(salesAuthThunks.changePassword(params))
          .unwrap()
          .then(() => {
            navigate(`/sales/${PATH.salesLogin}`);
            formik.resetForm();
          });
      }
    },
  });

  return (
    <CustomForm
      formTitle={"Восстановление пароля"}
      btnColor={"primary"}
      btnTitle={"Изменить пароль"}
      handleSubmit={formik.handleSubmit}
    >
      <PasswordInput
        name={"НОВЫЙ ПАРОЛЬ"}
        placeholder={"Введите новый пароль"}
        inputName={"password"}
        formik={formik}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
      <PasswordInput
        name={"ПОВТОРНЫЙ ВВОД ПАРОЛЯ"}
        placeholder={"Введите пароль повторно"}
        inputName={"confirmPassword"}
        formik={formik}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
    </CustomForm>
  );
};
