import { FC } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { muiTheme } from "../../../../../constants/customTheme.ts";
import { useNavigate } from "react-router-dom";
import s from "../WithdrawalsAction.module.scss";

export const SuccessBlock: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={s.withdrawalsActionBlock}>
      <div className={s.application}>Заявка на снятие денег принята!</div>
      <div className={s.applicationText}>
        Деньги придут в твой телеграм чат в течении 3 рабочих дней.
      </div>
      <div>
        <ThemeProvider theme={muiTheme}>
          <Button
            disabled={false}
            variant={"contained"}
            prefix={"main"}
            color={"success"}
            className={s.applicationButton}
            onClick={() => navigate("/sales/home")}
          >
            На главную страницу
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};
