import st from "./LogoAnimation.module.scss";
import LogoMobile from "../../assets/svg/logo-mobile.svg";
import LogoTelegivCom from "../../assets/svg/logo-telegive-come-black.svg";
import LogoMobileLight from "../../assets/svg/logo-mobile-light.svg";
import LogoTelegivComLight from "../../assets/svg/logo-telegive-come-light.svg";

type PropsType = {
  dark: boolean;
  onClickLogoAnimation: () => void;
};
export const LogoAnimation = ({ dark, onClickLogoAnimation }: PropsType) => {
  const handleOnClick = () => {
    onClickLogoAnimation();
  };
  return (
    <div className={st.wrapper}>
      <img
        onClick={handleOnClick}
        className={st.logoMobile}
        src={dark ? LogoMobile : LogoMobileLight}
        alt="Logo Telegiv"
      />

      <img
        src={dark ? LogoTelegivCom : LogoTelegivComLight}
        alt="Logo Telegiv"
        className={st.logoTelegivCom}
        onClick={handleOnClick}
      />
    </div>
  );
};
