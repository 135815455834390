import { FC } from "react";
import { BlockTitle } from "../blockTitle/BlockTitle.tsx";
import { NoContent } from "../noContent/NoContent.tsx";
import { TeamTable } from "../../../components/salesHome/team/teamTable/TeamTable.tsx";
import { TeammateType } from "../../../API/apiPublicManager.ts";
import { CustomButton } from "../../../components/customButton/CustomButton.tsx";
import s from "../../styles/CommonStyles.module.scss";

type PropsType = {
  team: TeammateType[];
  btnAction: () => void;
  variant: "sales" | "admin";
};
export const Team: FC<PropsType> = ({ team, btnAction, variant }) => {
  const isTeamEmpty = !team?.length;
  const noContentMessage =
    variant === "sales"
      ? "В твоей команде пока никого нет"
      : "В команде менеджера пока никого нет";

  return (
    <div className={s.clientsBlock}>
      <BlockTitle title={"Команда"} />
      {isTeamEmpty ? (
        <NoContent title={noContentMessage} />
      ) : (
        <>
          <TeamTable team={team} />
          <CustomButton
            title={"Вся команда"}
            textColor={"white"}
            color={"info"}
            width={"150"}
            margin={"23px 0 0 0"}
            font={"main"}
            callback={btnAction}
          />
        </>
      )}
    </div>
  );
};
