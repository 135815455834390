import { Section } from "../Section.tsx";
import clsx from "clsx";
import { aboutUsCards } from "./aboutUsCards.tsx";
import { Typography } from "../../../typography";
import { Card } from "../../../Card/Card.tsx";
import { GetCardDarkStatus } from "../../../../common/utils/getCardDarkStatus.ts";
import { screenSizes } from "../../../../common/const/screenSizes.ts";
import { IsSmallSizeScreen } from "../../../../common/utils/isSmallSizeScreen.ts";
import s from "./AboutUs.module.scss";

export const AboutUs = () => {
  const isSmallScreen = IsSmallSizeScreen();
  return (
    <Section className={s.section}>
      <div className={clsx("container", s.container)}>
        <Typography
          className={s.title}
          variant={isSmallScreen ? "header_secondary" : "title_block_768"}
          align={"center"}
          dark
        >
          <a href="https://telegiv.com" target="_blank" className={s.link}>
            Telegiv.com
          </a>{" "}
          - это сервис розыгрыша призов в Telegram
        </Typography>
        <div className={s.cardsContainer}>
          {aboutUsCards.map((card, index) => {
            return (
              <Card
                cardName={"aboutUs"}
                key={index}
                title={card.title}
                desc={card.desc}
                dark={GetCardDarkStatus(
                  [0],
                  [0],
                  screenSizes.mediumSmall,
                  index,
                )}
                img={card.img}
              ></Card>
            );
          })}
        </div>
        <Typography
          className={s.title}
          variant={isSmallScreen ? "header_secondary" : "title_block_768"}
          align={"center"}
          dark
        >
          C помощью{" "}
          <a href="https://telegiv.com" target="_blank" className={s.link}>
            telegiv.com
          </a>
          <br /> бренды и блогеры устраивают вирусные розыгрыши
          <br /> и за считанные дни привлекают на свои telegram каналы
          <br /> тысячи реальных подписчиков
        </Typography>
      </div>
    </Section>
  );
};
