import { MainLogo } from "../mainLogo/MainLogo.tsx";
import s from "./SalesHeader.module.scss";
import { isAuthorizedSelector } from "../../../selectors/salesAuthSelectors.ts";
import { HeaderMenu } from "./HeaderMenu.tsx";
import { useAppSelector } from "../../../hooks/storeHooks.ts";
import { PATH } from "../../path/path.ts";

export const SalesHeader = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);

  return (
    <div className={s.headerBackground}>
      <div className={s.headerContainer}>
        <div className={s.headerWrapper}>
          {isAuthorized ? (
            <>
              <MainLogo navigateLink={PATH.salesHome} toggleLogoWidth={775} />
              <HeaderMenu />
            </>
          ) : (
            <MainLogo
              navigateLink={`/${PATH.salesBaseUrl}${PATH.main}`}
              toggleLogoWidth={775}
            />
          )}
        </div>
      </div>
    </div>
  );
};
