import { WithdrawalsAction } from "./withdrawalsAction/WithdrawalsAction.tsx";
import s from "./SalesWithdrawals.module.scss";

export const SalesWithdrawals = () => {
  return (
    <div className={s.withdrawalsBlock}>
      <WithdrawalsAction />
    </div>
  );
};
