import { FC, useRef } from "react";
import { MySalesTable } from "../../../components/salesBalance/history/mySales/mySalesTable.tsx";
import { ManagersSalesTable } from "../../../components/salesBalance/history/managersSales/ManagersSalesTable.tsx";
import { BlockPaymentsTable } from "../../../components/salesBalance/history/blockPayments/BlockPaymentsTable.tsx";
import { MyPaymentsTable } from "../../../components/salesBalance/history/myPayments/MyPaymentsTable.tsx";
import {
  GetHistoryResponseType,
  HistoryCategoryType,
} from "../../../components/salesBalance/salesBalanceApi.ts";
import { HistoryButtonsType } from "../../types/commonTypes.ts";
import { useSearchParams } from "react-router-dom";
import s from "./ManagerHistoryTable.module.scss";

export type SetURLSearchParamsType = ReturnType<typeof useSearchParams>[1];

type PropsType = {
  historyCategory: HistoryCategoryType;
  onPageChange: (page: string) => void;
  currentPage: string;
  historyData: GetHistoryResponseType;
  historyButtons: HistoryButtonsType[];
  setSearchParams: SetURLSearchParamsType;
  setHistoryCategory: (type: HistoryCategoryType) => void;
};
export const ManagerHistoryTable: FC<PropsType> = ({
  historyCategory,
  onPageChange,
  currentPage,
  historyData,
  historyButtons,
  setSearchParams,
  setHistoryCategory,
}) => {
  const btnRefs = useRef<HTMLButtonElement[]>([]);

  const mappedHistoryButtons = historyButtons.map(
    ({ title, className, type }, index) => {
      const getRef = (el: HTMLButtonElement) => (btnRefs.current[index] = el);
      const onClickHandler = () => {
        setSearchParams({});
        setHistoryCategory(type);
        btnRefs.current[index].scrollIntoView({
          block: "nearest",
          inline: "center",
          behavior: "smooth",
        });
      };
      return (
        <button
          key={index}
          className={s[className]}
          onClick={onClickHandler}
          ref={getRef}
        >
          {title}
        </button>
      );
    },
  );

  return (
    <div className={s.balanceHistoryBlock}>
      <div className={s.header}>История</div>
      <div className={s.headerButtonsWrapper}>
        <div className={s.headerButtons}>{mappedHistoryButtons}</div>
      </div>
      {historyCategory === "mySales" && (
        <MySalesTable
          historyData={historyData}
          currentPage={currentPage}
          onChangePage={onPageChange}
        />
      )}
      {historyCategory === "teamSales" && (
        <ManagersSalesTable
          historyData={historyData}
          currentPage={currentPage}
          onChangePage={onPageChange}
        />
      )}
      {historyCategory === "withoutProfitSales" && (
        <BlockPaymentsTable
          historyData={historyData}
          currentPage={currentPage}
          onChangePage={onPageChange}
        />
      )}
      {historyCategory === "withdrawal" && (
        <MyPaymentsTable
          historyData={historyData}
          currentPage={currentPage}
          onChangePage={onPageChange}
        />
      )}
    </div>
  );
};
