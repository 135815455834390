import { Main } from "./section/main/Main.tsx";
import { AboutUs } from "./section/about-us/AboutUs.tsx";
import { Section3 } from "./section/section3/Section3.tsx";
import { HowWork } from "./section/how-work/HowWork.tsx";
import { SectionBigTitle } from "./section/sectionBigTitle/SectionBigTitle.tsx";
import { Account } from "./section/account/Account.tsx";
import { Faq } from "./section/faq/Faq.tsx";
import { Steps } from "./section/steps/Steps.tsx";
import { Pluses } from "./section/pluses/Pluses.tsx";
import { Start } from "./section/start/Start.tsx";
import { Header } from "../header/Header.tsx";
import { Green } from "../typography";
import "../../styles/index.scss";
import { Footer } from "../footer";

export const SalesLanding = () => {
  return (
    <div style={{ backgroundColor: "var(--color-violet-2)" }}>
      <Header />
      <Main />
      <AboutUs />
      <Section3 />
      <SectionBigTitle>
        <Green>зарабатывай</Green>
        <br /> с каждого клиента <br />
        <Green> до 200$ </Green>
      </SectionBigTitle>
      <HowWork />
      <SectionBigTitle largePadding>
        дари промокоды и
        <br />
        <Green>зарабатывай деньги</Green>
        <br />
      </SectionBigTitle>
      <Faq />
      <Account />
      <Steps />
      <Pluses />
      <Start />
      <Footer />
      {/*<SmallPopUpCookie />*/}
    </div>
  );
};
