import { FC } from "react";
import { TeamTableLayout } from "./TeamTableLayout.tsx";
import { TeamTableRow } from "./TeamTableRow.tsx";
import { TeammateType } from "../../../../API/apiPublicManager.ts";
import { serverDateFormat } from "../../../../common/utils/serverDateFormat.ts";

type PropsType = {
  team: TeammateType[];
};
export const TeamTable: FC<PropsType> = ({ team }) => {
  const teamTableRows = team.map(
    ({ createdAt, id, clientsCount, email, tg_userName, profit }) => {
      const regDate = serverDateFormat(createdAt);
      return (
        <TeamTableRow
          key={id}
          clients={clientsCount}
          email={email}
          telegram={tg_userName}
          income={profit}
          regDate={regDate}
        />
      );
    },
  );

  return <TeamTableLayout>{teamTableRows}</TeamTableLayout>;
};
