import { RootState } from "../../bll/store/store.ts";

const balanceDataSelector = (state: RootState) =>
  state.salesBalance.balanceData;
const isBalanceFetchingSelector = (state: RootState) =>
  state.salesBalance.isBalanceFetching;
const historyDataSelector = (state: RootState) =>
  state.salesBalance.historyData;
const isSalesBalanceHistoryFetchingSelector = (state: RootState) =>
  state.salesBalance.isHistoryFetching;

export {
  balanceDataSelector,
  isBalanceFetchingSelector,
  historyDataSelector,
  isSalesBalanceHistoryFetchingSelector,
};
