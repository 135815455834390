import { FC } from "react";
import s from "./TeamTable.module.scss";

type PropsType = {
  text: string | number;
};

export const TeamTableCell: FC<PropsType> = ({ text }) => {
  return (
    <div className={s.commonCell}>
      <span>{text}</span>
    </div>
  );
};
