import BgcImg from "../../../../assets/image/landing/mainPageBgc.png";
import { Section } from "../Section.tsx";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Green, Typography } from "../../../typography";
import { Button } from "../../../button";
import { PATH } from "../../../../common/path/path.ts";
import { ScrollLink } from "../../../scrollLink/scrollLink.tsx";
import s from "./Main.module.scss";


export const Main = () => {
    function isMobileDevice() {
        return navigator.userAgent.indexOf("Mobile") !== -1;
    }

    return (
        <Section dark className={s.mainSection}>
            <div className={clsx("container", s.container)}>
                <div className={s.right}>
                    <Typography variant={"title_block"} align={"start"}>
                        зарабатывай <br/>
                        на партнёрке <br/> от
                        <br/>
                        <Green>
                            <a href="https://telegiv.com" target="_blank" className={s.link}>
                                Telegiv.com
                            </a>
                        </Green>
                    </Typography>
                    <div className={s.btnContainer}>
                        <Button
                            variant={"primary"}
                            as={NavLink}
                            to={`/sales/${PATH.registration}`}
                        >
                            присоединиться
                        </Button>
                        <ScrollLink to={"faq"}>
                            <Button variant={"secondary"}>подробнее</Button>
                        </ScrollLink>
                    </div>
                </div>
            </div>
            <div className={s.bgc}>
                <img src={BgcImg} alt=""/>
                {!isMobileDevice() &&
                    <iframe
                        scrolling="no"
                        height="500px"
                        width="500px"
                        src="https://rive.app/community/5308-11093-cute-interactive-robot/embed"
                        allowFullScreen
                        className={s.frame}
                    ></iframe>
                }
            </div>
        </Section>
    );
};
