import { useState } from "react";
import { CustomForm } from "../../customForm/CustomForm.tsx";
import { CommonInput } from "../../commonInput/CommonInput.tsx";
import { PasswordInput } from "../../passwordInput/PasswordInput.tsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormikValuesType } from "../../../common/types/commonTypes.ts";
import { usePasswordVisible } from "../../../hooks/usePasswordVisible.ts";
import { PATH } from "../../../common/path/path.ts";
import { SalesLoginParams } from "../../../API/apiSalesAuth.ts";
import { useAppDispatch } from "../../../hooks/storeHooks.ts";
import { salesAuthThunks } from "../../../bll/reducers/salesAuthSlice.ts";

export const SalesLogin = () => {
  const dispatch = useAppDispatch();
  const { visibility, toggleVisibility } = usePasswordVisible();
  const [error, setError] = useState<null | string>(null);

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Пожалуйста, введи Email")
        .email("Невалидный email"),
      password: Yup.string()
        .required("Пожалуйста, введи пароль")
        .min(6, "Пароль должен быть больше 5 символов"),
    }),
    onSubmit: (values) => {
      if (values.email && values.password) {
        const params: SalesLoginParams = {
          email: values.email.toLowerCase(),
          password: values.password,
        };
        dispatch(salesAuthThunks.login(params))
          .unwrap()
          .then(() => {
            formik.resetForm();
          })
          .catch(() => {
            setError("Неверный email или пароль");
            const timer = setTimeout(() => {
              setError(null);
              clearTimeout(timer);
            }, 3000);
          });
      }
    },
  });

  return (
    <CustomForm
      helperText={"Нет аккаунта?"}
      linkText={"регистрация"}
      formTitle={"Вход"}
      btnColor={error ? "warning" : "primary"}
      btnTitle={error || "Авторизация"}
      handleSubmit={formik.handleSubmit}
      linkTo={`/sales/${PATH.salesRegistration}`}
    >
      <CommonInput
        name={"EMAIL"}
        placeholder={"Email"}
        formik={formik}
        inputName={"email"}
      />
      <PasswordInput
        name={"ПАРОЛЬ"}
        placeholder={"Пароль"}
        forgotPassLink={true}
        linkTo={`/sales/${PATH.salesRecoverPass}`}
        formik={formik}
        inputName={"password"}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
    </CustomForm>
  );
};
