import { Navigate, Outlet } from "react-router-dom";
import { isAuthorizedSelector } from "../selectors/salesAuthSelectors.ts";
import { Container } from "../common/components/container/Container.tsx";
import { SalesHeader } from "../common/components/header/SalesHeader.tsx";
import { useAppSelector } from "../hooks/storeHooks.ts";
import { PATH } from "../common/path/path.ts";

export const SalesRequireAuth = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  return (
    <>
      <SalesHeader />
      {isAuthorized ? (
        <Container>
          <Outlet />
        </Container>
      ) : (
        <Navigate to={PATH.salesLogin} replace={true} />
      )}
    </>
  );
};
