import { ComponentPropsWithoutRef, FC, ReactNode } from "react";
import { Section } from "../Section.tsx";
import clsx from "clsx";
import { Green, Typography } from "../../../typography";
import s from "./Steps.module.scss";
import { IsSmallSizeScreen } from "../../../../common/utils/isSmallSizeScreen.ts";

export const Steps = () => {
  return (
    <Section dark>
      <div className={clsx("container", s.container)}>
        <div>
          <Typography
            variant={"title_block"}
            align={IsSmallSizeScreen() ? "center" : "end"}
          >
            <Green>план действий</Green>
          </Typography>
        </div>
        <div className={s.steps}>
          <Item
            color={"var(--color-green-3)"}
            desc={
              <span className={s.center}>
                проходишь <br />
                регистрацию
              </span>
            }
          ></Item>
          <div className={s.line}></div>
          <Item
            color={"var(--color-green-1-tr50)"}
            desc={
              <span>
                даришь <br />
                промо-коды
              </span>
            }
          ></Item>
          <div className={s.line}></div>
          <Item
            color={"var(--color-violet-1)"}
            desc={
              <span>
                немного <br />
                ждешь
              </span>
            }
          ></Item>
          <div className={s.line}></div>
          <Item
            color={"var(--color-violet-2)"}
            desc={
              <span>
                получаешь <br />
                свои деньги
              </span>
            }
          ></Item>
        </div>
      </div>
    </Section>
  );
};

type ItemProps = {
  color?: string;
  desc: ReactNode;
} & ComponentPropsWithoutRef<"div">;
const Item: FC<ItemProps> = ({ color, style, desc }) => {
  const styles = {
    ...(color && { backgroundColor: color }),
    ...style,
  };
  return (
    <div className={s.step}>
      <div className={s.item} style={styles}></div>
      <div className={s.desc}>
        <Typography
          variant={"title_any_cads"}
          className={s.descText}
          align={"center"}
        >
          {desc}
        </Typography>
      </div>
    </div>
  );
};
