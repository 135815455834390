import { FC } from "react";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FormikValuesType } from "../../../common/types/commonTypes.ts";
import * as Yup from "yup";
import { ActiveWindowType } from "../SalesSettings.tsx";
import { managerProfileDataSelector } from "../../../selectors/salesAuthSelectors.ts";
import { salesSettingsThunks } from "../salesSettingsSlice.ts";
import { isChangeUserDataLoadingSelector } from "../salesSettingsSelectors.ts";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks.ts";
import { setAppErrorAC } from "../../../bll/reducers/appReducer.ts";
import { CommonInput } from "../../commonInput/CommonInput.tsx";
import s from "../SalesSettings.module.scss";

type Props = {
  setActiveWindow: (activeWindow: ActiveWindowType) => void;
};

export const MainSettings: FC<Props> = ({ setActiveWindow }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const managerProfileData = useAppSelector(managerProfileDataSelector);
  const isChangeUserDataLoading = useAppSelector(
    isChangeUserDataLoadingSelector,
  );

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      phone: managerProfileData.phoneNumber,
      tg_username: managerProfileData.tgUsername || "",
    },
    validationSchema: Yup.object({
      tg_username: Yup.string()
        .required("Пожалуйста, введите telegram username")
        .matches(
          /^(?:|(https?:\/\/)?(|www)[.]?((t|telegram)\.me)\/)[a-zA-Z0-9_]{5,32}$/,
          "Ошибка",
        ),
    }),
    onSubmit: (values) => {
      if (values.phone === null && values.tg_username) {
        dispatch(
          salesSettingsThunks.changeUserData({
            tgUsername: values.tg_username,
          }),
        );
      }
      if (values.phone && values.tg_username) {
        dispatch(
          salesSettingsThunks.changeUserData({
            phoneNumber: values.phone,
            tgUsername: values.tg_username,
          }),
        );
      }
      if (values.phone === "" && values.tg_username) {
        dispatch(
          setAppErrorAC({ error: "Вы не можете удалить номер телефона" }),
        );
        formik.setFieldValue("phone", managerProfileData.phoneNumber);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={s.inputs}>
        <CommonInput
          name={"Email"}
          placeholder={managerProfileData.email}
          formik={formik}
          inputName={"email"}
          disable={true}
        />
        <CommonInput
          name={"Телефон"}
          placeholder={"Телефон"}
          formik={formik}
          inputName={"phone"}
        />
        <CommonInput
          name={"Телеграм"}
          placeholder={"Телеграм"}
          formik={formik}
          inputName={"tg_username"}
        />
      </div>
      <div className={s.changePassLink}>
        <Link onClick={() => setActiveWindow("confirm")}>Изменить пароль</Link>
      </div>
      <div className={s.buttons}>
        <Button
          disabled={false}
          variant={"contained"}
          prefix={"main"}
          color={"success"}
          onClick={() => navigate("/sales/home")}
          style={{ fontSize: "15px" }}
        >
          На главную
        </Button>
        <Button
          disabled={isChangeUserDataLoading}
          variant={"contained"}
          prefix={"main"}
          color={"error"}
          type={"submit"}
          style={{ fontSize: "15px" }}
        >
          Сохранить
        </Button>
      </div>
    </form>
  );
};
