import { Green, Typography } from "../../../typography";
import { TermsContainer } from "../TermsContainer.tsx";
import { Desc, Point, Points, Section } from "../TermsComponents.tsx";
import s from "../Terms.module.scss";

export const PrivacyPolicy = () => {
  return (
    <TermsContainer>
      <div className={s.main}>
        <Typography
          variant={"title_block"}
          align={"start"}
          dark
          className={s.privacyPolicyTitle}
        >
          политика <Green>конфиденциальности</Green>
        </Typography>
      </div>
      <div className={s.body}>
        <Section number={"1"} title={"ОБЩИЕ ПОЛОЖЕНИЯ"}>
          <Desc>
            Политика обработки персональных данных (далее – Политика)
            разработана в соответствии с Федеральным законом от 27.07.2006.
            №152-ФЗ «О персональных данных» (далее – ФЗ-152).
          </Desc>
          <Desc>
            Настоящая Политика определяет порядок обработки персональных данных
            и меры по обеспечению безопасности персональных данных в Telegiv Ltd
            (далее – Общество) с целью защиты прав и свобод человека и
            гражданина при обработке его персональных данных, в том числе защиты
            прав на неприкосновенность частной жизни, личную и семейную тайну.
          </Desc>
          <Desc>
            В Политике используются следующие основные понятия:
            <Points>
              <Point number={"1.1"}>
                Автоматизированная обработка персональных данных
              </Point>
              <Desc>
                Обработка персональных данных с помощью средств вычислительной
                техники;
              </Desc>
              <Point number={"1.2"}>Блокирование персональных данных</Point>
              <Desc>
                Временное прекращение обработки персональных данных (за
                исключением случаев, если обработка необходима для уточнения
                персональных данных);
              </Desc>
              <Point number={"1.3"}>
                Информационная система персональных данных
              </Point>
              <Desc>
                Совокупность содержащихся в базах данных персональных данных, и
                обеспечивающих их обработку информационных технологий и
                технических средств;
              </Desc>
              <Point number={"1.4"}>Обезличивание персональных данных</Point>
              <Desc>
                Действия, в результате которых невозможно определить без
                использования дополнительной информации принадлежность
                персональных данных конкретному субъекту персональных данных;
              </Desc>
              <Point number={"1.5"}>Обработка персональных данных</Point>
              <Desc>
                Любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без
                использования таких средств с персональными данными, включая
                сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных;
              </Desc>
              <Point number={"1.6"}>Общество</Point>
              <Desc>
                Государственный орган, муниципальный орган, юридическое или
                физическое лицо, самостоятельно или совместно с другими лицами
                организующие и (или) осуществляющие обработку персональных
                данных, а также определяющие цели обработки персональных данных,
                состав персональных данных, подлежащих обработке, действия
                (операции), совершаемые с персональными данными;
              </Desc>
              <Point number={"1.7"}>Персональные данные</Point>
              <Desc>
                Любая информация, относящаяся к прямо или косвенно определенному
                или определяемому физическому лицу (субъекту персональных
                данных);
              </Desc>
              <Point number={"1.8"}>Предоставление персональных данных</Point>
              <Desc>
                действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц;
              </Desc>
              <Point number={"1.9"}>Распространение персональных данных</Point>
              <Desc>
                Действия, направленные на раскрытие персональных данных
                неопределенному кругу лиц (передача персональных данных) или на
                ознакомление с персональными данными неограниченного круга лиц,
                в том числе обнародование персональных данных в средствах
                массовой информации, размещение в информационно-
                телекоммуникационных сетях или предоставление доступа к
                персональным данным каким-либо иным способом;
              </Desc>
              <Point number={"1.10"}>
                Трансграничная передача персональных данных
              </Point>
              <Desc>
                Передача персональных данных на территорию иностранного
                государства органу власти иностранного государства, иностранному
                физическому или иностранному юридическому лицу.
              </Desc>
              <Point number={"1.11"}>Уничтожение персональных данных</Point>
              <Desc>
                Действия, в результате которых невозможно восстановить
                содержание персональных данных в информационной системе
                персональных данных и (или) результате которых уничтожаются
                материальные носители персональных данных;
              </Desc>
            </Points>
          </Desc>
          <Desc>
            Компания обязана опубликовать или иным образом обеспечить
            неограниченный доступ к настоящей Политике обработки персональных
            данных в соответствии с ч. 2 ст. 18.1. ФЗ-152.
          </Desc>
        </Section>
        <Section
          number={"2"}
          title={"ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ"}
        >
          <Desc>
            Политика обработки персональных данных (далее – Политика)
            разработана в соответствии с Федеральным законом от 27.07.2006.
            №152-ФЗ «О персональных данных» (далее – ФЗ-152).
          </Desc>
          <Desc>
            Настоящая Политика определяет порядок обработки персональных данных
            и меры по обеспечению безопасности персональных данных в АО «Амоцрм»
            (далее – Общество) с целью защиты прав и свобод человека и
            гражданина при обработке его персональных данных, в том числе защиты
            прав на неприкосновенность частной жизни, личную и семейную тайну.
          </Desc>
          <Desc>
            В Политике используются следующие основные понятия:
            <Points>
              <Point number={"2.1"}>
                Автоматизированная обработка персональных данных
              </Point>
              <Desc>
                Обработка персональных данных с помощью средств вычислительной
                техники;
              </Desc>
              <Point number={"2.2"}>Блокирование персональных данных</Point>
              <Desc>
                Временное прекращение обработки персональных данных (за
                исключением случаев, если обработка необходима для уточнения
                персональных данных);
              </Desc>
              <Point number={"2.3"}>
                Информационная система персональных данных
              </Point>
              <Desc>
                Совокупность содержащихся в базах данных персональных данных, и
                обеспечивающих их обработку информационных технологий и
                технических средств;
              </Desc>
              <Point number={"2.4"}>Обезличивание персональных данных</Point>
              <Desc>
                Действия, в результате которых невозможно определить без
                использования дополнительной информации принадлежность
                персональных данных конкретному субъекту персональных данных;
              </Desc>
              <Point number={"2.5"}>Обработка персональных данных</Point>
              <Desc>
                Любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без
                использования таких средств с персональными данными, включая
                сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных;
              </Desc>
              <Point number={"2.6"}>Общество</Point>
              <Desc>
                Государственный орган, муниципальный орган, юридическое или
                физическое лицо, самостоятельно или совместно с другими лицами
                организующие и (или) осуществляющие обработку персональных
                данных, а также определяющие цели обработки персональных данных,
                состав персональных данных, подлежащих обработке, действия
                (операции), совершаемые с персональными данными;
              </Desc>
              <Point number={"2.7"}>Персональные данные</Point>
              <Desc>
                Любая информация, относящаяся к прямо или косвенно определенному
                или определяемому физическому лицу (субъекту персональных
                данных);
              </Desc>
              <Point number={"2.8"}>Предоставление персональных данных</Point>
              <Desc>
                действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц;
              </Desc>
              <Point number={"2.9"}>Распространение персональных данных</Point>
              <Desc>
                Действия, направленные на раскрытие персональных данных
                неопределенному кругу лиц (передача персональных данных) или на
                ознакомление с персональными данными неограниченного круга лиц,
                в том числе обнародование персональных данных в средствах
                массовой информации, размещение в информационно-
                телекоммуникационных сетях или предоставление доступа к
                персональным данным каким-либо иным способом;
              </Desc>
              <Point number={"2.10"}>
                Трансграничная передача персональных данных
              </Point>
              <Desc>
                Передача персональных данных на территорию иностранного
                государства органу власти иностранного государства, иностранному
                физическому или иностранному юридическому лицу.
              </Desc>
              <Point number={"2.11"}>Уничтожение персональных данных</Point>
              <Desc>
                Действия, в результате которых невозможно восстановить
                содержание персональных данных в информационной системе
                персональных данных и (или) результате которых уничтожаются
                материальные носители персональных данных;
              </Desc>
            </Points>
          </Desc>
          <Desc>
            Компания обязана опубликовать или иным образом обеспечить
            неограниченный доступ к настоящей Политике обработки персональных
            данных в соответствии с ч. 2 ст. 18.1. ФЗ-152.
          </Desc>
        </Section>
        <Section number={"3"} title={"ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ"}>
          <Desc>
            <Points>
              <Point number={"3.1"}>
                Согласие субъекта персональных данных на обработку его
                персональных данных
              </Point>
              <Desc>
                Субъект персональных данных принимает решение о предоставлении
                его персональных данных и дает согласие на их обработку
                свободно, своей волей и в своем интересе. Согласие на обработку
                персональных данных может быть дано субъектом персональных
                данных или его представителем в любой позволяющей подтвердить
                факт его получения форме, если иное не установлено федеральным
                законом.
              </Desc>
              <Desc>
                Обязанность предоставить доказательство получения согласия
                субъекта персональных данных на обработку его персональных
                данных или доказательство наличия оснований, указанных в ФЗ-152,
                возлагается на Обществоа.
              </Desc>
              <Point number={"3.2"}>Права субъекта персональных данных</Point>
              <Desc>
                Субъект персональных данных имеет право на получение у Обществоа
                информации, касающейся обработки его персональных данных, если
                такое право не ограничено в соответствии с федеральными
                законами. Субъект персональных данных вправе требовать от
                Обществоа уточнения его персональных данных, их блокирования или
                уничтожения в случае, если персональные данные являются
                неполными, устаревшими, неточными, незаконно полученными или не
                являются необходимыми для заявленной цели обработки, а также
                принимать предусмотренные законом меры по защите своих прав.
              </Desc>
              <Desc>
                Обработка персональных данных в целях продвижения товаров,
                работ, услуг на рынке путем осуществления прямых контактов с
                потенциальным потребителем с помощью средств связи, а также в
                целях политической агитации допускается только при условии
                предварительного согласия субъекта персональных данных.
                Указанная обработка персональных данных признается
                осуществляемой без предварительного согласия субъекта
                персональных данных, если Компания не докажет, что такое
                согласие было получено.
              </Desc>
              <Desc>
                Общество обязан немедленно прекратить по требованию субъекта
                персональных данных обработку его персональных данных в
                вышеуказанных целях.
              </Desc>
              <Desc>
                Запрещается принятие на основании исключительно
                автоматизированной обработки персональных данных решений,
                порождающих юридические последствия в отношении субъекта
                персональных данных или иным образом затрагивающих его права и
                законные интересы, за исключением случаев, предусмотренных
                федеральными законами, или при наличии согласия в письменной
                форме субъекта персональных данных.
              </Desc>
              <Desc>
                Если субъект персональных данных считает, что Общество
                осуществляет обработку его персональных данных с нарушением
                требований ФЗ-152 или иным образом нарушает его права и свободы,
                субъект персональных данных вправе обжаловать действия или
                бездействие Обществоа в Уполномоченный орган по защите прав
                субъектов персональных данных или в судебном порядке.
              </Desc>
              <Desc>
                Субъект персональных данных имеет право на защиту своих прав и
                законных интересов, в том числе на возмещение убытков и (или)
                компенсацию морального вреда в судебном порядке.
              </Desc>
            </Points>
          </Desc>
        </Section>
        <Section
          number={"4"}
          title={"ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ"}
        >
          <Desc>
            Безопасность персональных данных, обрабатываемых Обществоа,
            обеспечивается реализацией правовых, организационных и технических
            мер, необходимых для обеспечения требований федерального
            законодательства в области защиты персональных данных.
          </Desc>
          <Desc>
            Для предотвращения несанкционированного доступа к персональным
            данным Обществоом применяются следующие организационно-технические
            меры:
            <Points>
              <Point number={"4.1"}>
                Назначение должностных лиц, ответственных за организацию
                обработки и защиты персональных данных;
              </Point>
              <Point number={"4.2"}>
                Ограничение состава лиц, имеющих доступ к персональным данным;
              </Point>
              <Point number={"4.3"}>
                Ознакомление субъектов с требованиями федерального
                законодательства и нормативных документов Обществоа по обработке
                и защите персональных данных;
              </Point>
              <Point number={"4.4"}>
                Организация учета, хранения и обращения носителей информации;
              </Point>
              <Point number={"4.5"}>
                Определение угроз безопасности персональных данных при их
                обработке, формирование на их основе моделей угроз;
              </Point>
              <Point number={"4.6"}>
                Разработка на основе модели угроз системы защиты персональных
                данных;
              </Point>
              <Point number={"4.7"}>
                Проверка готовности и эффективности использования средств защиты
                информации;
              </Point>
              <Point number={"4.8"}>
                Разграничение доступа пользователей к информационным ресурсам и
                программно- аппаратным средствам обработки информации;
              </Point>
              <Point number={"4.9"}>
                Регистрация и учет действий пользователей информационных систем
                персональных данных;
              </Point>
              <Point number={"4.10"}>
                Использование антивирусных средств и средств восстановления
                системы защиты персональных данных;
              </Point>
              <Point number={"4.11"}>
                Применение в необходимых случаях средств межсетевого
                экранирования, обнаружения вторжений, анализа защищенности и
                средств криптографической защиты информации;
              </Point>
              <Point number={"4.12"}>
                Организация пропускного режима на территорию Обществоа, охраны
                помещений с техническими средствами обработки персональных
                данных.
              </Point>
            </Points>
          </Desc>
        </Section>
        <Section number={"5"} title={"ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ"}>
          <Desc>
            Иные права и обязанности Обществоа, как Обществоа персональных
            данных определяются законодательством Российской Федерации в области
            персональных данных.
          </Desc>
          <Desc>
            Должностные лица Обществоа, виновные в нарушении норм, регулирующих
            обработку и защиту персональных данных, несут материальную,
            дисциплинарную, административную, гражданско-правовую или уголовную
            ответственность в порядке, установленном федеральными законами.
          </Desc>
        </Section>
      </div>
    </TermsContainer>
  );
};
