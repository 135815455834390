import { FC, PropsWithChildren } from "react";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions.ts";
import s from "./TeamTable.module.scss";

export const TeamTableLayout: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowDimensions();
  const CHANGE_COL_TITLE_WIDTH = 705;
  const isTitleChange = width <= CHANGE_COL_TITLE_WIDTH;

  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th className={s.dateCol}>Дата регистрации</th>
          <th className={s.emailCol}>email</th>
          <th className={s.clientsCol}>Клиентов</th>
          <th className={s.incomeCol}>
            {isTitleChange ? "Принес" : "Принес денег"}
          </th>
          <th className={s.telegramCol}>Telegram</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
