import { FC, useEffect } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { muiTheme } from "../../../../../constants/customTheme.ts";
import { ActiveWindowType } from "../WithdrawalsAction.tsx";
import { useNavigate } from "react-router-dom";
import { balanceDataSelector } from "../../../salesBalanceSelectors.ts";
import { salesBalanceThunks } from "../../../salesBalanceSlice.ts";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/storeHooks.ts";
import s from "../WithdrawalsAction.module.scss";

type Props = {
  setActiveWindow: (activeWindow: ActiveWindowType) => void;
};

export const ActionBlock: FC<Props> = ({ setActiveWindow }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tg_userName = useAppSelector(
    (state) => state.salesAuth.managerProfile.tgUsername,
  );
  const balanceData = useAppSelector(balanceDataSelector);

  useEffect(() => {
    dispatch(salesBalanceThunks.getBalance());
  }, [dispatch]);

  return (
    <div className={s.withdrawalsActionBlock}>
      <div className={s.withdrawalsHeader}>Вывод заработка</div>
      <div className={s.balanceSent}>
        {balanceData.balance}$ будут отправлены в твой Telegram
      </div>
      <div className={s.usernameHeader}>
        Твой username в Telegram указан верно?
      </div>
      <div className={s.username}>@{tg_userName}</div>
      <div className={s.buttons}>
        <ThemeProvider theme={muiTheme}>
          <Button
            disabled={false}
            variant={"contained"}
            prefix={"main"}
            color={"success"}
            className={s.button}
            onClick={() => setActiveWindow("password")}
          >
            Да
          </Button>
          <Button
            disabled={false}
            variant={"contained"}
            prefix={"main"}
            color={"error"}
            className={s.button}
            onClick={() => navigate("/sales/settings")}
          >
            Нет
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};
