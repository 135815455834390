import { FC } from "react";
import { ClientsTableLayout } from "./ClientsTableLayout.tsx";
import { ClientsTableRow } from "./ClientsTableRow.tsx";
import { ClientType } from "../../../../API/apiPublicManager.ts";
import { serverDateFormat } from "../../../../common/utils/serverDateFormat.ts";

type PropsType = {
  clients: ClientType[];
};
export const ClientsTable: FC<PropsType> = ({ clients }) => {
  const clientsTableRows = clients.map(
    ({
      id,
      count_subscribes,
      usr_tg,
      email_address,
      rate_plan,
      creation_timestamp,
    }) => {
      const regDate = serverDateFormat(creation_timestamp);
      return (
        <ClientsTableRow
          key={id}
          subscribers={count_subscribes}
          email={email_address}
          telegram={usr_tg ?? "Telegram пока не указан"}
          regDate={regDate}
          ratePlan={rate_plan}
        />
      );
    },
  );

  return <ClientsTableLayout>{clientsTableRows}</ClientsTableLayout>;
};
