import { useEffect } from "react";
import { BlockTitle } from "../../../common/components/blockTitle/BlockTitle.tsx";
import { SalesPagination } from "../../../common/components/pagination/Pagination.tsx";
import { TeamTableLayout } from "./teamTable/TeamTableLayout.tsx";
import { TeamTableRow } from "./teamTable/TeamTableRow.tsx";
import { serverDateFormat } from "../../../common/utils/serverDateFormat.ts";
import {
  getTeamSelector,
  isFetchingSelector,
  teamPagesCountSelector,
} from "../../../selectors/managerPublicSelectors.ts";
import { publicManagerThunks } from "../../../bll/reducers/publicManagerSlice.ts";
import { PreLoader } from "../../../common/components/preLoader/PreLoader.tsx";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks.ts";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams.ts";

type SalesAllTeamParams = "page" | "size";
export const AllTeam = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(teamPagesCountSelector);
  const team = useAppSelector(getTeamSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const [searchParams, setSearchParams] =
    useCustomSearchParams<SalesAllTeamParams>();

  const currentPage = searchParams.page || "1";
  const pageSize = searchParams.size || "10";

  useEffect(() => {
    dispatch(
      publicManagerThunks.getTeam({ page: currentPage, size: pageSize }),
    );
  }, [currentPage]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const teamTableRows = team.map(
    ({ id, profit, createdAt, email, clientsCount, tg_userName }) => {
      const regDate = serverDateFormat(createdAt);
      return (
        <TeamTableRow
          key={id}
          clients={clientsCount}
          email={email}
          telegram={tg_userName}
          income={profit}
          regDate={regDate}
        />
      );
    },
  );
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <BlockTitle title={"Команда"} />
      {isFetching ? (
        <PreLoader />
      ) : (
        <>
          <TeamTableLayout>{teamTableRows}</TeamTableLayout>
          <SalesPagination
            page={currentPage}
            onChange={onChangePage}
            pagesCount={pagesCount}
            disabled={isFetching}
          />
        </>
      )}
    </div>
  );
};
