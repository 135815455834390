import { FC, PropsWithChildren } from "react";
import exit from "../../assets/svg/exit.svg";
import s from "./InOutButton.module.css";

type PropsType = {
  callback: () => void;
  btnTitle: "Вход" | "Выход";
};
export const InOutButton: FC<PropsType & PropsWithChildren> = ({
  callback,
  btnTitle,
}) => {
  return (
    <div className={s.exitContainer} onClick={callback}>
      <img src={exit} alt="inOutButton" />
      <span className={s.exit}>{btnTitle}</span>
    </div>
  );
};
