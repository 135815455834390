import { instance } from "../../API/instance.ts";

export const salesSettingsApi = {
  changeUserData(arg: ChangeUserDataArgs) {
    return instance.put("", arg);
  },
};

export type ChangeUserDataArgs = {
  tgUsername: string;
  phoneNumber?: string;
};
