import { FC } from "react";
import { MySalesTableLayout } from "./mySalesTableLayout.tsx";
import { MySalesTableRow } from "./mySalesTableRow.tsx";
import { SalesPagination } from "../../../../common/components/pagination/Pagination.tsx";
import {
  GetHistoryResponseType,
  HistoryItemResponseType,
} from "../../salesBalanceApi.ts";
import { CircularProgress } from "@mui/material";
import { isSalesBalanceHistoryFetchingSelector } from "../../salesBalanceSelectors.ts";
import { useAppSelector } from "../../../../hooks/storeHooks.ts";
import s from "./mySalesTable.module.scss";

type Props = {
  historyData: GetHistoryResponseType;
  currentPage: string;
  onChangePage: (page: string) => void;
};

const historyItems: HistoryItemResponseType[] = [
  {
    id: 11,
    managerId: 1,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer2@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
  {
    id: 12,
    managerId: 2,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer2@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
  {
    id: 13,
    managerId: 3,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer3@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
  {
    id: 14,
    managerId: 4,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer4@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
  {
    id: 15,
    managerId: 5,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: "customer5@example.com",
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
];

export const MySalesTable: FC<Props> = ({
  historyData,
  currentPage,
  onChangePage,
}) => {
  const isSalesBalanceHistoryFetching = useAppSelector(
    isSalesBalanceHistoryFetchingSelector,
  );

  return (
    <div className={s.noHistoryTextWrapper}>
      {isSalesBalanceHistoryFetching && (
        <div className={s.circularProgress}>
          <CircularProgress />
        </div>
      )}
      {!isSalesBalanceHistoryFetching && historyData.items.length === 0 ? (
        <>
          <div className={s.noHistoryText}>Нет истории транзакций</div>
          <div className={s.emptyTable}>
            <MySalesTableLayout>
              {historyItems.map((i) => {
                return <MySalesTableRow key={i.id} historyItem={i} />;
              })}
            </MySalesTableLayout>
          </div>
        </>
      ) : (
        <div>
          <MySalesTableLayout>
            {historyData.items?.map((i) => {
              return <MySalesTableRow key={i.id} historyItem={i} />;
            })}
          </MySalesTableLayout>
          <SalesPagination
            page={currentPage}
            pagesCount={historyData.pagesCount}
            onChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
};
