import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import s from "../WithdrawalsAction.module.scss";

export const AccordionBlock = () => {
  return (
    <div className={s.accordion}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>Как и куда я получу деньги?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>Перевод будет выполнен в криптовалюте TON.</p>
            <p>
              Денежные средства будут отправлены на твой Telegram аккаунт из
              аккаунта <b>@telegivcom</b>
            </p>
            <p>
              Для перевода мы используем официальный Telegram бот -{" "}
              <b>@wallet</b>
            </p>
            <br />
            <p>
              После поступления денежных средств их можно будет вывести прям из
              Telegram на: банковскую карту, ЮMoney, Payeer и в десятки подобных
              платежных систем.
            </p>
            <br />
            <p>Перевод денежных средств занимает до 3 рабочих дней.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>
            Как вывести деньги из Telegram на карту?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div>
              <p>Подробная инструкция по выводу денег из Telegram:</p>
            </div>
            <ul>
              <li>
                <p>
                  Заходим в официальный Telegram бот - @<b>wallet</b>
                </p>
              </li>
              <li>
                <p>
                  Нажимаем - <b>/start</b>
                </p>
              </li>
              <li>
                <p>
                  Нажимаем - <b>Добавить в меню вложений.</b>
                </p>
              </li>
              <li>
                <p>Проходим верификацию по номеру телефона.</p>
              </li>
              <li>
                <p>
                  В левом нижнем углу нажимаем кнопку <b>“меню”</b>.
                </p>
              </li>
              <li>
                <p>
                  Нажимаем на кнопку “Главное меню” - <b>/menu</b>
                </p>
              </li>
              <li>
                <p>
                  Нажимаем на - <b>P2P Маркет</b>.
                </p>
              </li>
              <li>
                <p>
                  Нажимаем на - <b>Продать</b>.
                </p>
              </li>
              <li>
                <p>
                  В левом верхнем углу нажимаем на - <b>Оплата</b>.
                </p>
              </li>
              <li>
                <p>Выбираем куда выводить деньги.</p>
              </li>
              <li>
                <p>Выбираем валюту.</p>
              </li>
              <li>
                <p>
                  Выбираем лучшее предложение. Нажимаем - <b>Продать</b>.
                </p>
              </li>
              <li>
                <p>Вводим сумму в Ton.</p>
              </li>
              <li>
                <p>
                  Нажимаем - <b>Далее</b>.
                </p>
              </li>
              <li>
                <p>Следуем инструкции.</p>
              </li>
              <li>
                <p>Готово!</p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
