import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
  useId,
  useState,
} from "react";
import { Component, Typography } from "../typography";
import clsx from "clsx";
import s from "./Button.module.scss";

export type VariantType = "primary" | "secondary" | "link";
export type ButtonProps<T extends ElementType = "button"> = {
  as?: T;
  variant?: VariantType;
  className?: string;
  children?: ReactNode;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  nonAdapt?: boolean;
} & ComponentPropsWithoutRef<T>;

export const Button = <T extends ElementType = "button">(
  props: ButtonProps<T> &
    Omit<ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>,
) => {
  const {
    variant = "primary",
    className,
    as: Component = "button",
    children,
    iconStart,
    iconEnd,
    size = "large",
    dark = false,
    nonAdapt = false,
    ...rest
  } = props;

  // const typography = variant === 'link' ? 'Subtitle_1' : 'Subtitle_2'
  const classNames = clsx(
    s[variant],
    className,
    size && s[size],
    dark && s.dark,
    nonAdapt && s.nonAdapt,
  );
  const classNamesNoAnimation = clsx(classNames, s.noAnimation);

  let typographyVariant = "btn_l" as Component;
  if (size === "small") typographyVariant = "btn_s";
  if (size === "medium") typographyVariant = "btn_m";
  if (size === "large") typographyVariant = "btn_l";
  if (variant === "link") {
    if (size === "small") typographyVariant = "very_small_text";
    if (size === "medium") typographyVariant = "small_text";
    if (size === "large") typographyVariant = "small_text";
  }

  const id = useId();
  useEffect(() => {
    const btn = document.getElementById(id);
    if (btn) {
      btn.onmousemove = (e: MouseEvent) => {
        const rect = btn.getBoundingClientRect();
        const y = e.clientY - rect.top;
        const x = e.clientX - rect.left;
        btn.style.setProperty("--x", x + "px");
        btn.style.setProperty("--y", y + "px");
      };
    }
  }, [id]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  function isMobileDevice() {
    return navigator.userAgent.indexOf("Mobile") !== -1;
  }

  return (
    <Component
      id={id}
      className={isMobile ? classNamesNoAnimation : classNames}
      {...rest}
    >
      <div>
        {iconStart}
        <Typography variant={typographyVariant} className={s.text}>
          {children}
        </Typography>
        {iconEnd}
      </div>
    </Component>
  );
};
