import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { SalesAuth } from "./SalesAuth.tsx";
import { PATH } from "../common/path/path.ts";
import { SalesLogin } from "../components/salesAuth/login/SalesLogin.tsx";
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "../constants/customTheme.ts";
import { SalesRegistration } from "../components/salesAuth/registration/SalesRegistration.tsx";
import { SalesRecoverPass } from "../components/salesAuth/recoverPassword/SalesRecoverPass.tsx";
import { SetNewPassword } from "../components/salesAuth/recoverPassword/SetNewPassword.tsx";
import { SalesRequireAuth } from "./SalesRequireAuth.tsx";
import { SalesHome } from "../components/salesHome/SalesHome.tsx";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks.ts";
import { salesAuthThunks } from "../bll/reducers/salesAuthSlice.ts";
import { isInitializedSelector } from "../selectors/salesAuthSelectors.ts";
import { SalesBalance } from "../components/salesBalance/SalesBalance.tsx";
import { AllClients } from "../components/salesHome/clients/AllClients.tsx";
import { AllTeam } from "../components/salesHome/team/AllTeam.tsx";
import { KnowledgeBase } from "../components/salesKnowledgeBase/KnowledgeBase.tsx";
import { SalesSettings } from "../components/salesSettings/SalesSettings.tsx";
import { SalesWithdrawals } from "../components/salesBalance/withdrawals/SalesWithdrawals.tsx";
import { SalesLanding } from "./SalesLanding.tsx";
import { CookiePage } from "../components/salesLanding/termsOfUsePages/cookiePage/CookiePage.tsx";
import { PrivacyPolicy } from "../components/salesLanding/termsOfUsePages/privacyPolicy/PrivacyPolicy.tsx";
import { PreLoader } from "../common/components/preLoader/PreLoader.tsx";

export const SalesRoot = () => {
  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector(isInitializedSelector);

  useEffect(() => {
    dispatch(salesAuthThunks.getMe());
  }, []);

  if (!isInitialized) return <PreLoader />;
  return (
    <ThemeProvider theme={muiTheme}>
      <Routes>
        <Route element={<SalesAuth />}>
          <Route path={PATH.salesLogin} element={<SalesLogin />} />
          <Route
            path={PATH.salesRegistration}
            element={<SalesRegistration />}
          />
          <Route path={PATH.salesRecoverPass} element={<SalesRecoverPass />} />
          <Route path={PATH.salesNewPassword} element={<SetNewPassword />} />
        </Route>
        <Route element={<SalesRequireAuth />}>
          <Route path={PATH.salesHome} element={<SalesHome />} />
          <Route path={PATH.salesAllClients} element={<AllClients />} />
          <Route path={PATH.salesAllTeam} element={<AllTeam />} />
          <Route path={PATH.salesBalance} element={<SalesBalance />} />
          <Route path={PATH.salesKnowledgeBase} element={<KnowledgeBase />} />
          <Route path={PATH.salesSettings} element={<SalesSettings />} />
          <Route path={PATH.salesWithdrawals} element={<SalesWithdrawals />} />
        </Route>
        <Route element={<SalesLanding />}>
          <Route path={PATH.salesCookie} element={<CookiePage />} />
          <Route path={PATH.salesPrivacyPolicy} element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};
