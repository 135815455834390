import axios from "axios";
import { isTokenShouldRefresh, TOKENS } from "../common/utils/tokenHelpers.ts";
import { salesAuthApi } from "./apiSalesAuth.ts";

export const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_TELEGIV_API + '/sales',
});

instance.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(TOKENS.SALES)}`;
  config.withCredentials = true;
  return config;
});

instance.interceptors.response.use((value) => {
  const isRefresh = isTokenShouldRefresh(TOKENS.SALES_EXP_TIME);
  isRefresh && salesAuthApi.salesRefreshToken();
  return value;
});
