import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import { Icon } from "../../icons/IconWrapper.tsx";
import { ICON } from "../../icons/components/Types.ts";
import s from "./Terms.module.scss";

type Props = {
  children: ReactNode;
};
export const TermsContainer: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  // let backIconSize = 64;
  // if (IsSmallSizeScreen()) backIconSize = 32;
  return (
    <div className={s.root}>
      <div className={clsx("container", s.cookieContainer)}>
        <button className={s.backBtn} onClick={() => navigate(-1)}>
          <Icon iconId={ICON.ARROW_LEFT} width={"64px"} height={"64px"} />
        </button>
        {children}
      </div>
    </div>
  );
};
