import { FC } from "react";
import { BlockTitle } from "../blockTitle/BlockTitle.tsx";
import { NoContent } from "../noContent/NoContent.tsx";
import { ClientsTable } from "../../../components/salesHome/clients/clinetsTable/ClientsTable.tsx";
import { ClientType } from "../../../API/apiPublicManager.ts";
import { CustomButton } from "../../../components/customButton/CustomButton.tsx";
import s from "../../styles/CommonStyles.module.scss";

type PropsType = {
  clients: ClientType[];
  btnAction: () => void;
  variant: "admin" | "sales";
};
export const Clients: FC<PropsType> = ({ clients, btnAction, variant }) => {
  const isClientsEmpty = !clients?.length;
  const noContentMessage =
    variant === "sales"
      ? "У тебя пока нет клиентов"
      : "У менеджера пока нет клиентов";

  return (
    <div className={s.clientsBlock}>
      <BlockTitle title={"Клиенты"} />
      {isClientsEmpty ? (
        <NoContent title={noContentMessage} />
      ) : (
        <>
          <ClientsTable clients={clients} />
          <CustomButton
            title={"Все клиенты"}
            textColor={"white"}
            color={"info"}
            width={"150"}
            margin={"23px 0 0 0"}
            font={"main"}
            callback={btnAction}
          />
        </>
      )}
    </div>
  );
};
