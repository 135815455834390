import { DecodedToken } from "../types/commonTypes.ts";
import { jwtDecode } from "jwt-decode";

export enum TOKENS {
  SALES = "salesAccessToken",
  SALES_EXP_TIME = "salesAccessTokenExpTime",
  CUSTOMER = "customerAccessToken",
  CUSTOMER_EXP_TIME = "customerAccessTokenExpTime",
}

export const setTokenInfoToLS = (
  token: string,
  tokenLocalStorageKey: string,
  tokenLocalStorageExpTimeKey: string,
) => {
  const decodedToken: DecodedToken = jwtDecode(token);
  localStorage.setItem(
    tokenLocalStorageExpTimeKey,
    decodedToken.exp.toString(),
  );
  localStorage.setItem(tokenLocalStorageKey, token);
};

export const isTokenShouldRefresh = (
  tokenLocalStorageKeyExpTime: string,
): boolean | null => {
  const currentTime = (new Date().getTime() / 1000).toFixed();
  const tokenExpireTime = localStorage.getItem(tokenLocalStorageKeyExpTime);
  if (!tokenExpireTime) {
    return null;
  } else {
    return Number(tokenExpireTime) - Number(currentTime) <= 1200;
  }
};

export const removeTokenInfoFromLS = (
  tokenLocalStorageKey: string,
  tokenLocalStorageExpTimeKey: string,
) => {
  localStorage.removeItem(tokenLocalStorageKey);
  localStorage.removeItem(tokenLocalStorageExpTimeKey);
};
