import { FC } from "react";
import s from "./MyPaymentsTable.module.scss";
import { HistoryItemResponseType } from "../../salesBalanceApi.ts";
import { serverDateFormat } from "../../../../common/utils/serverDateFormat.ts";

type Props = {
  historyItem: HistoryItemResponseType;
};
export const MyPaymentsTableRow: FC<Props> = ({ historyItem }) => {
  let date;
  if (historyItem.dateTransaction) {
    date = serverDateFormat(historyItem.dateTransaction);
  }

  return (
    <tr>
      <td className={s.dateRow}>
        <div className={s.tableCell}>
          <span>{date ? date : "В ожидании"}</span>
        </div>
      </td>
      <td className={s.statusRow}>
        <div className={s.tableCellShortCount}>
          {historyItem.status === "Pending" && <span>В ожидании</span>}
          {historyItem.status === "Finished" && <span>Выплачено</span>}
        </div>
      </td>
      <td className={s.incomeRow}>
        <div className={s.tableCell}>
          <span>{historyItem.sum}$</span>
        </div>
      </td>
    </tr>
  );
};
