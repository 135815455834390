import { Section } from "../Section.tsx";
import clsx from "clsx";
import { plusesCards } from "./plusesCards.tsx";
import { Green, Typography } from "../../../typography";
import { Card } from "../../../Card/Card.tsx";
import { GetCardDarkStatus } from "../../../../common/utils/getCardDarkStatus.ts";
import { screenSizes } from "../../../../common/const/screenSizes.ts";
import s from "./Pluses.module.scss";

export const Pluses = () => {
  return (
    <Section className={s.section}>
      <div className={clsx("container", s.container)}>
        <div>
          <Typography variant={"title_block"} align={"start"} mb={4}>
            <Green>а еще...</Green>
          </Typography>
        </div>
        <div className={s.cardsContainer}>
          {plusesCards.map((card, index) => {
            return (
              <Card
                cardName={"pluses"}
                key={index}
                title={card.title}
                desc={card.desc}
                dark={GetCardDarkStatus([0], [1], screenSizes.small, index)}
                img={card.img}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
};
