import { FC } from "react";
import noContent from "../../../assets/svg/no_content.svg";

type PropsType = {
  title: string;
};

export const NoContent: FC<PropsType> = ({ title }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        opacity: "0.4",
        fontSize: "15px",
      }}
    >
      <img src={noContent} alt="no-content" style={{ width: "150px" }} />
      <span>{title}</span>
    </div>
  );
};
