import { SalesRoot } from "./privateRoutes/SalesRoot.tsx";
import { SalesLanding } from "./components/salesLanding/SalesLanding.tsx";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import { setInviterIdAC } from "./bll/reducers/salesAuthSlice.ts";
import { useDispatch } from "react-redux";

function App() {
  console.log(
    "=>(App.tsx:8) App started on port ",
    import.meta.env.VITE_APP_TELEGIV_API,
    import.meta.env,
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (!queryParams) return;
    dispatch(setInviterIdAC(+queryParams));
  }, [queryParams]);

  return (
    <Routes>
      <Route path={"sales/*"} element={<SalesRoot />}></Route>
      <Route path={"/sales"} element={<SalesLanding />}></Route>
      <Route path={"/"} element={<Navigate to={"/sales"} />}></Route>
    </Routes>
  );
}

export default App;
