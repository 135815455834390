import { FC } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormikProps } from "formik";
import { FormikValuesType } from "../../common/types/commonTypes.ts";

type PropsType = {
  name: string;
  placeholder?: string | null;
  formik: FormikProps<FormikValuesType>;
  inputName: "email" | "tg_username" | "codeFromEmail" | "phone";
  disable?: boolean;
};
export const CommonInput: FC<PropsType> = ({
  name,
  placeholder,
  formik,
  inputName,
  disable,
}) => {
  const titleStyle = {
    fontFamily: "Mulish, sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.3px",
    color: "#9FA2B4",
    alignSelf: "flex-start",
    marginBottom: "6px",
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component={"span"} sx={titleStyle}>
        {name}
      </Typography>
      <TextField
        inputProps={{ style: { fontSize: "17px" } }}
        sx={{ height: "64px", width: "100%", mb: "10px" }}
        size="small"
        variant="outlined"
        placeholder={placeholder ?? ""}
        margin="none"
        {...formik.getFieldProps(inputName)}
        value={formik.values[inputName] ?? ""}
        error={!!(formik.touched[inputName] && formik.errors[inputName])}
        helperText={!!formik.touched[inputName] && formik.errors[inputName]}
        FormHelperTextProps={{ sx: { fontSize: "12px" } }}
        disabled={disable}
      />
    </Box>
  );
};
