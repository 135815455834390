import { FC } from "react";
import { HistoryItemResponseType } from "../../salesBalanceApi.ts";
import { serverDateFormat } from "../../../../common/utils/serverDateFormat.ts";
import s from "./ManagersSales.module.scss";

type Props = {
  historyItem: HistoryItemResponseType;
};
export const ManagersSalesTableRow: FC<Props> = ({ historyItem }) => {
  const date = serverDateFormat(historyItem.purchaseDate?.toString());

  return (
    <tr>
      <td className={s.dateRow}>
        <div className={s.tableCell}>
          <span>{date}</span>
        </div>
      </td>
      <td className={s.actionRow}>
        <div className={s.tableCell}>
          <span>
            {historyItem.customerEmail} оплатил {historyItem.costPlan}$
          </span>
        </div>
      </td>
      <td className={s.percentRow}>
        <div className={s.tableCellShortCount}>
          <span>{historyItem.myProfitPercent}%</span>
        </div>
      </td>
      <td className={s.incomeRow}>
        <div className={s.tableCell}>
          <span>{historyItem.myProfit}$</span>
        </div>
      </td>
    </tr>
  );
};
