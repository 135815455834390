import { useNavigate } from "react-router-dom";
import { getTeamSelector } from "../../../selectors/managerPublicSelectors.ts";
import { Team } from "../../../common/components/team/Team.tsx";
import { PATH } from "../../../common/path/path.ts";
import { useAppSelector } from "../../../hooks/storeHooks.ts";

export const ManagerTeam = () => {
  const navigate = useNavigate();
  const toAllTeam = () => {
    navigate(`/sales/${PATH.salesAllTeam}`);
  };

  const team = useAppSelector(getTeamSelector);

  return <Team team={team} btnAction={toAllTeam} variant={"sales"} />;
};
