import { Outlet } from "react-router-dom";
import { isAuthorizedSelector } from "../selectors/salesAuthSelectors.ts";
import { useAppSelector } from "../hooks/storeHooks.ts";
import { Header } from "../components/header/Header.tsx";

export const SalesLanding = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  return (
    <>
      <div style={{ backgroundColor: "var(--color-violet-2)" }}>
        <Header isNav dark isAuth={isAuthorized} />
        <Outlet />
        {/*<Footer />*/}
      </div>
    </>
  );
};
