import { muiTheme } from "../../../constants/customTheme.ts";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { balanceDataSelector } from "../salesBalanceSelectors.ts";
import { useAppSelector } from "../../../hooks/storeHooks.ts";
import s from "./Balance.module.scss";

export const Balance = () => {
  const navigate = useNavigate();
  const balanceData = useAppSelector(balanceDataSelector);

  return (
    <div className={s.balanceBlock}>
      <div className={s.balanceHeader}>Баланс</div>
      <div className={s.balanceCount}>{balanceData?.balance}$</div>
      <ThemeProvider theme={muiTheme}>
        <Button
          disabled={!balanceData.balance || !balanceData.canWithdraw}
          variant={"contained"}
          prefix={"main"}
          color={"secondary"}
          className={s.button}
          onClick={() => navigate("/sales/withdrawals")}
        >
          Вывод денег
        </Button>
      </ThemeProvider>
    </div>
  );
};
