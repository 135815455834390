import { SalesButtonGroup } from "./SalesButtonGroup.tsx";
import { HeaderBurger } from "./HeaderBurger.tsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/storeHooks.ts";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions.ts";
import { salesAuthThunks } from "../../../bll/reducers/salesAuthSlice.ts";
import { PATH } from "../../path/path.ts";
import { InOutButton } from "../../../components/inOutButton/InOutButton.tsx";

export const HeaderMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const SHOW_BURGER_WIDTH = 500;
  const isBurgerShow = width <= SHOW_BURGER_WIDTH;
  const handleLogout = () => dispatch(salesAuthThunks.logout());

  const menuItems = [
    { buttonTitle: "Баланс", buttonAction: () => navigate(PATH.salesBalance) },
    {
      buttonTitle: "База знаний",
      buttonAction: () => navigate(PATH.salesKnowledgeBase),
    },
    {
      buttonTitle: "Настройки",
      buttonAction: () => navigate(PATH.salesSettings),
    },
  ];

  return (
    <div style={{ display: "flex", gap: "40px" }}>
      {isBurgerShow ? (
        <HeaderBurger handleLogout={handleLogout} menuItems={menuItems} />
      ) : (
        <>
          <SalesButtonGroup />
          <InOutButton callback={handleLogout} btnTitle={"Выход"} />
        </>
      )}
    </div>
  );
};
