import { FC } from "react";
import { StatsWrapper } from "./StatsWrapper.tsx";
import { StatsItem } from "./StatsItem.tsx";
import {
  managersSelector,
  teamClientsSelector,
} from "../../../selectors/managerInfoSelectors.ts";
import { useAppSelector } from "../../../hooks/storeHooks.ts";

type PropsType = {};
export const TeamCount: FC<PropsType> = () => {
  const teamClientsCount = useAppSelector(teamClientsSelector);
  const managerClientsCount = useAppSelector(managersSelector);

  return (
    <StatsWrapper>
      <StatsItem
        title={"Участников в команде"}
        value={managerClientsCount}
        headingTitle
      />
      <StatsItem title={"Клиентов у команды"} value={teamClientsCount} />
    </StatsWrapper>
  );
};
