import { FC } from "react";
import { TeamTableCell } from "./TeamTableCell.tsx";
import s from "./TeamTable.module.scss";

type PropsType = {
  regDate: string;
  email: string;
  clients: number;
  income: number;
  telegram: string;
};
export const TeamTableRow: FC<PropsType> = ({
  regDate,
  email,
  clients,
  income,
  telegram,
}) => {
  return (
    <tr>
      <td className={s.date}>
        <TeamTableCell text={regDate} />
      </td>
      <td className={s.email}>
        <TeamTableCell text={email} />
      </td>
      <td className={s.clients}>
        <TeamTableCell text={clients} />
      </td>
      <td className={s.income}>
        <TeamTableCell text={`${income}$`} />
      </td>
      <td className={s.telegram}>
        <TeamTableCell text={telegram} />
      </td>
    </tr>
  );
};
