import { useState } from "react";
import { MainSettings } from "./mainSettings/MainSettings.tsx";
import { ConfirmPage } from "./confirmPage/ConfirmPage.tsx";
import { CheckEmail } from "./checkEmail/CheckEmail.tsx";
import s from "./SalesSettings.module.scss";

export type ActiveWindowType = "settings" | "confirm" | "checkEmail";

export const SalesSettings = () => {
  const [activeWindow, setActiveWindow] =
    useState<ActiveWindowType>("settings");

  return (
    <div className={s.SalesSettingsBlock}>
      <h2>Настройки</h2>
      {activeWindow === "settings" && (
        <MainSettings setActiveWindow={setActiveWindow} />
      )}
      {activeWindow === "confirm" && (
        <ConfirmPage setActiveWindow={setActiveWindow} />
      )}
      {activeWindow === "checkEmail" && <CheckEmail />}
    </div>
  );
};
