import Gift from "../../../../assets/image/landing/cards/gift.svg?react";
import Ticket from "../../../../assets/image/landing/cards/ticket-star.svg?react";
import Profile from "../../../../assets/image/landing/cards/profile-add.svg?react";
import Wallet from "../../../../assets/image/landing/cards/wallet-money.svg?react";

export const howWorkCards = [
  {
    title: "получи \n промокод",
    desc: "После регистрации у тебя в кабинете появится личный промокод.",
    img: <Ticket />,
  },
  {
    title: "подари \n его клиенту",
    desc: "Расскажи клиенту про Telegiv.com и подари ему промокод на 1000 подписчиков..",
    img: <Gift />,
  },
  {
    title: "подожди его регистрации",
    desc: "При регистрации клиент вводит промокод и закрепляется за тобой.",
    img: <Profile />,
  },
  {
    title: "получи \n свои деньги",
    desc: "С каждой оплаты клиента, ты будешь получать 15%.",
    img: <Wallet />,
  },
];
