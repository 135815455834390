import { FC, PropsWithChildren } from "react";
import s from "./ManagersSales.module.scss";

export const ManagersSalesTableLayout: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th className={s.dateCol}>Дата транзакции</th>
          <th className={s.actionCol}>Действие</th>
          <th className={s.percentCol}>Процент</th>
          <th className={s.incomeCol}>Заработок</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
