import { Section } from "../Section.tsx";
import Phone from "../../../../assets/image/landing/account.png";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Green, Typography } from "../../../typography";
import { Button } from "../../../button";
import { PATH } from "../../../../common/path/path.ts";
import s from "./Account.module.scss";

export const Account = () => {
  return (
    <div className={s.account}>
      <Section className={s.bgcLeft}></Section>
      <Section className={s.bgcRight}></Section>
      <div className={clsx("container", s.container)}>
        <Section className={s.left}>
          <Typography variant={"title_block"} align={"start"} mb={8} dark>
            <Green>Создай</Green>
            <br />
            свой личный
            <br />
            <Green>кабинет</Green>
          </Typography>
          <Typography
            variant={"title_any_cads"}
            align={"start"}
            className={s.desc}
            dark
          >
            и начинай зарабатывать!
          </Typography>
          <Button
            variant={"primary"}
            dark={true}
            as={NavLink}
            to={`/sales/${PATH.registration}`}
          >
            создать личный кабинет
          </Button>
        </Section>
        <Section className={s.right}>
          <div className={s.img}>
            <img src={Phone} alt="" />
          </div>
        </Section>
      </div>
    </div>
  );
};
