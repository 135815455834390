import { instance } from "./instance.ts";
import { ManagerInfoType } from "./apiSalesAuth.ts";

export const publicManagerApi = {
  getClients(params: ParamsType) {
    return instance.get<CommonDataType<ClientType>>("clients", { params });
  },
  getTeam(params: ParamsType) {
    return instance.get<CommonDataType<TeammateType>>("teams", { params });
  },
  getInfo() {
    return instance.get<InfoDataType>("info");
  },
};

export type ClientType = {
  id: number;
  email_address: string;
  usr_tg: string | null;
  count_subscribes: number;
  creation_timestamp: string;
  rate_plan: string;
};
export type TeammateType = {
  email: string;
  clientsCount: number;
  profit: number;
  tg_userName: string;
  createdAt: string;
  id: number;
};
export type CommonDataType<T> = {
  totalCount: number;
  pagesCount: number;
  items: Array<T>;
};

export type InfoDataType = {
  manager: ManagerInfoType;
  teamMembers: TeamMembersType;
  profit: ProfitType;
  managerClients: ManagerClientsType;
};

export type TeamMembersType = {
  managers: number;
  teamClients: number;
};

export type ProfitType = {
  total: number;
  incomeFromClients: number;
  incomeFromTeam: number;
};

export type ManagerClientsType = {
  paidClients: number;
  freeClients: number;
  totalClients: number;
};

export type ParamsType = {
  size?: string;
  page?: string;
};
