import { Section } from "../Section.tsx";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Green, Typography } from "../../../typography";
import { PATH } from "../../../../common/path/path.ts";
import { Button } from "../../../button";
import s from "./Start.module.scss";

export const Start = () => {
  return (
    <Section dark className={s.section}>
      <div className={clsx("container", s.container)}>
        <Typography variant={"title_block"} align={"end"}>
          Ну что? <Green>начнем?</Green>
        </Typography>
        <Button
          variant={"primary"}
          as={NavLink}
          to={`/sales/${PATH.registration}`}
        >
          стать частью команды
        </Button>
      </div>
    </Section>
  );
};
