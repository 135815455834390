import {
  setAppErrorAC,
  SetAppErrorACType,
  setAppStatusAC,
  SetAppStatusACType,
} from "../../bll/reducers/appReducer.ts";
import { Dispatch } from "redux";

export const handleServerNetworkError = (
  message: string,
  dispatch: ErrorUtilsDispatchType,
) => {
  dispatch(setAppStatusAC({ status: "failed" }));
  dispatch(setAppErrorAC({ error: message }));
};

export type ErrorUtilsDispatchType = Dispatch<
  SetAppErrorACType | SetAppStatusACType
>;
