import Cup from "../../../../assets/image/landing/cards/cup.svg?react";
import Timer from "../../../../assets/image/landing/cards/timer.svg?react";
import s from "./AboutUs.module.scss";

const text1 = (
  <>
    <span className={s.longText}>
      <a href="https://telegiv.com" target="_blank" className={s.link}>
        TELEGIV.com
      </a>{" "}
      - это
    </span>
    <span className={s.shortText}>Мы</span> крупнейший сервис розыгрыша призов в
    Telegram
  </>
);
const text2 = (
  <>
    <span className={s.longText}>
      За 10+ лет команда Telegiv провела более 1000 розыгрышей и по опыту знает,
      как делать максимально вирусные розыгрыши.
    </span>
    <span className={s.shortText}>Мы создаем вирусные розыгрыши</span>
  </>
);
export const aboutUsCards = [
  { title: "top", desc: text1, img: <Cup /> },
  {
    title: "> 10 ЛЕТ",
    desc: text2,
    img: <Timer />,
  },
];
