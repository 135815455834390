import { FC } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { FormikProps } from "formik";
import { FormikValuesType } from "../../../../common/types/commonTypes.ts";

type PropsType = {
  placeholder: string;
  formik: FormikProps<FormikValuesType>;
  inputName: "password" | "confirmPassword";
  visibility: "password" | "text";
  toggleVisibility: () => void;
};
export const WithdrawalsPasswordInput: FC<PropsType> = ({
  placeholder,
  formik,
  inputName,
  visibility,
  toggleVisibility,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        autoComplete="off"
        size="small"
        type={visibility}
        variant="outlined"
        placeholder={placeholder}
        margin="none"
        {...formik.getFieldProps(inputName)}
        error={!!(formik.touched[inputName] && formik.errors[inputName])}
        helperText={!!formik.touched[inputName] && formik.errors[inputName]}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={toggleVisibility}
              position="end"
              sx={{ cursor: "pointer" }}
            >
              {visibility === "password" ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
