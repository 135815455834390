import { useEffect, useState } from "react";
import {
  salesBalanceActions,
  salesBalanceThunks,
} from "../salesBalanceSlice.ts";
import { HistoryCategoryType } from "../salesBalanceApi.ts";
import { historyDataSelector } from "../salesBalanceSelectors.ts";
import { ManagerHistoryTable } from "../../../common/components/managerHistoryTable/ManagerHistoryTable.tsx";
import { HistoryButtonsType } from "../../../common/types/commonTypes.ts";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks.ts";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams.ts";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions.ts";

type BalanceHistoryParams = "page" | "size";
export const BalanceHistory = () => {
  const dispatch = useAppDispatch();
  const historyData = useAppSelector(historyDataSelector);
  const [historyCategory, setHistoryCategory] =
    useState<HistoryCategoryType>("mySales");

  const [searchParams, setSearchParams] =
    useCustomSearchParams<BalanceHistoryParams>();
  const currentPage = searchParams.page || "1";
  const pageSize = searchParams.size || "10";

  useEffect(() => {
    dispatch(salesBalanceActions.setEmptyHistoryData());
    dispatch(
      salesBalanceThunks.getHistory({
        historyCategory: historyCategory,
        page: +currentPage,
        size: +pageSize,
      }),
    );
  }, [dispatch, historyCategory, currentPage]);

  const { width } = useWindowDimensions();
  const mobileVariantTriggerWidth = 700;
  const managerSalesButtonTitle =
    width <= mobileVariantTriggerWidth
      ? "Продажи менеджеров"
      : "Продажи моих менеджеров";

  const historyButtons: HistoryButtonsType[] = [
    {
      title: "Мои продажи",
      className: historyCategory === "mySales" ? "headerButtonsActive" : "",
      type: "mySales",
    },
    {
      title: managerSalesButtonTitle,
      className: historyCategory === "teamSales" ? "headerButtonsActive" : "",
      type: "teamSales",
    },
    {
      title: "Заблокированные выплаты",
      className:
        historyCategory === "withoutProfitSales" ? "headerButtonsActive" : "",
      type: "withoutProfitSales",
    },
    {
      title: "Мои выплаты",
      className: historyCategory === "withdrawal" ? "headerButtonsActive" : "",
      type: "withdrawal",
    },
  ];
  const onPageChange = (page: string) => {
    setSearchParams({ page: page });
  };

  return (
    <ManagerHistoryTable
      historyButtons={historyButtons}
      setHistoryCategory={setHistoryCategory}
      historyCategory={historyCategory}
      historyData={historyData}
      currentPage={currentPage}
      onPageChange={onPageChange}
      setSearchParams={setSearchParams}
    />
  );
};
