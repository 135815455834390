import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { managerPromoCodeSelector } from "../../selectors/salesAuthSelectors.ts";
import { useAppSelector } from "../../hooks/storeHooks.ts";
import s from "./KnowledgeBase.module.scss";

export const KnowledgeBase = () => {
  const promoCode = useAppSelector(managerPromoCodeSelector);

  return (
    <div className={s.accordion}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>Как я могу заработать?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              <b>Способ 1:</b>
            </p>
            <p>
              Ты раздаешь свой личный промокод людям, которым будет интересен
              наш сервис: рекламные агентства, маркетологи брендов, магазины,
              заведения, блогеры, администраторы телеграм-каналов и т.д.
            </p>
            <br />
            <p>
              Введя твой промокод при регистрации, клиент получает "тестовый"
              тариф не за 30$, а бесплатно. После регистрации, клиент
              закрепляется за тобой, и с каждой его покупки ты пожизненно
              получаешь 15%.
            </p>
            <br />
            <p>
              <b>Способ 2: </b>
            </p>
            <p>
              После того, как у тебя появится 5 платных клиентов, ты сможешь
              собрать собственную команду менеджеров продаж, и с каждой их
              продажи ты пожизненно будешь зарабатывать 10%
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>Сколько я могу заработать?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              1 приглашенный тобой клиент, со временем принесет тебе 201$. 1
              клиент, которого пригласил твой менеджер, со временем принесет
              тебе 134$
            </p>
            <br />
            <p>
              Приглашая клиентов и менеджеров каждый день, твой заработок каждый
              месяц будет становиться все больше и больше. Через пару месяцев
              активной работы - ты сможешь зарабатывать по несколько тысяч
              долларов в месяц.
            </p>
            <br />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>Где искать клиентов?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>Тебе нужны:</p>
            <ul>
              <li>
                <p>владельцы небольших бизнесов</p>
              </li>
              <li>
                <p>маркетологи</p>
              </li>
              <li>
                <p>рекламные агентства</p>
              </li>
              <li>
                <p>блогеры</p>
              </li>
              <li>
                <p>администраторы Telegram каналов</p>
              </li>
            </ul>
            <p>
              Используй поиск в Instagram, Telegram и Google. Существуют
              специальные сервисы, которые смогут помочь тебе в поиске,
              например:
            </p>
            <br />
            <p>
              Данный сервис, поможет тебе найти все instagram аккаунты, чьи
              владельцы в описании профиля написали к примеру - “маркетолог”.
            </p>
            <p>
              Таким образом ты в пару кликов соберешь всех маркетологов, все
              магазины, агентства, кафе и тд.
            </p>
            <p>
              Ссылка на сервис -{" "}
              <a
                href="https://segmento-target.ru/partner/26158"
                rel={"noreferrer"}
                target={"_blank"}
              >
                segmento-target.ru
              </a>
            </p>
            <br />
            <p>
              С помощью данного сервиса ты сможешь найти все Telegram каналы.
            </p>
            <p>
              Вводишь ключевое слово в поиск и тебе выдаются все каналы по
              запрашиваемой теме.
            </p>
            <p>
              Далее переходишь в описание канала и находишь контакт
              администратора.
            </p>
            <p>
              Ссылка на сервис -{" "}
              <a
                href="https://telemetr.me/r/s4dzAOU"
                rel={"noreferrer"}
                target={"_blank"}
              >
                telemetr.me
              </a>
            </p>
            <br />
            <p>Это самая крупная телеграм биржа.</p>
            <p>Здесь ты сможешь найти список каналов разбитых по темам.</p>
            <p>
              Переходи на канал. Заходи в описание канала и пиши администратору.
            </p>
            <p>
              Ссылка на сервис -{" "}
              <a
                href="https://telega.in/?r=tsX8N6Pg"
                rel={"noreferrer"}
                target={"_blank"}
              >
                telega.in
              </a>
            </p>
            <br />
            <p>Это лишь малая часть сервисов, которые могут тебе помочь.</p>
            <p>
              Но запомни.. Что бы добиться успеха - подходи к работе креативно.
            </p>
            <p>
              Ты можешь отправлять по 30-50 сообщений в день, а можешь купить
              рекламу в инстаграм или телеграм.
            </p>
            <p>Можешь писать в тематических чатах и форумах.</p>
            <p>
              Или к примеру собрать свою команду менеджеров из арбитражников и
              зарабатывать на их работе кучу денег.. Вариантов масса.{" "}
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>Что писать клиентам?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>Тебе не нужно ничего продавать.</p>
            <p>Ты можешь просто написать:</p>
            <br />
            <p>Привет!</p>
            <p>Я могу помочь вам с рекламой.</p>
            <p>
              Есть сервис, который дает брендам десятки тысяч реальных
              подписчиков в телеграм каналы за считанные дни.
            </p>
            <p>
              У меня есть промокод на тариф за 30$ - могу отдать вам бесплатно.
            </p>
            <p>Получите первую тысячу потенциальных клиентов.</p>
            <p>Интересно?</p>
            <br />
            <p>А потом пришлешь ссылку на сайт и свой промокод.</p>
            <p>И все... клиент у тебя в кармане.</p>
            <p>
              Теперь ты каждый раз при покупке им тарифа - получаешь свои 15%.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={s.accordionHeader}>
            Пример диалога с менеджером рекламного агенства
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              <b>Менеджер Telegiv: </b>
            </p>
            <p>День добрый! </p>
            <p>Занимаетесь ли вы рекламой брендов в Telegram?</p>
            <p>Может быть у вас уже были такие запросы? </p>
            <br />
            <p>
              <b>Агентство:</b>
            </p>
            <p>Да, мы работаем с Telegram. </p>
            <p>
              <b>Или</b>
            </p>
            <p>Нет, подобного опыта у нас нет. </p>
            <br />
            <p>
              <b>Менеджер Telegiv: </b>
            </p>
            <p>В таком случае, Telegiv.com вам очень сильно сможет помочь. </p>
            <p>Это сервис розыгрыша призов в Telegram. </p>
            <p>
              С помощью него за пару минут можно создать собственного Telegram
              бота для розыгрыша призов и получать десятки тысяч живых
              подписчиков в свои Telegram каналы.{" "}
            </p>
            <br />
            <p>
              Каждый участник розыгрыша получает лотерейный билет за подписку на
              канал спонсора розыгрыша.{" "}
            </p>
            <br />
            <p>
              А так же, каждый участник розыгрыша может увеличить свои шансы на
              победу пригласив в розыгрыш своих друзей.
            </p>
            <br />
            <p>
              И за каждого ногого приглашенного друга - система повышает
              вознаграждение. Сначала +3 билета, потом +4 , +5 и тд. Таким
              образом розыгрыши рекламируют себя сами и получаются безумно
              вирусными.{" "}
            </p>
            <br />
            <p>
              Кстати, у вашего бота будет искусственный интеллект, кому/когда и
              какое сообщение нужно отравить, чтобы максимально замотивировать
              участников розыгрышей приглашать как можно больше своих друзей.{" "}
            </p>
            <br />
            <p>
              Если вам интересно, то я могу организовать вам бесплатный доступ к
              сервису. Как раз опробуете){" "}
            </p>
            <br />
            <p>Дать бесплатный доступ?</p>
            <br />
            <p>
              <b>Агентство: </b>
            </p>
            <p>Да , нам это интересно.</p>
            <br />
            <p>
              <b>Менеджер: </b>
            </p>
            <p>При регистрации введите этот промокод: </p>
            <br />
            <div style={{ textAlign: "left" }}>
              <span style={{ color: "#27AE60", fontSize: "22px" }}>
                <b>{promoCode}</b>
              </span>
            </div>
            <br />
            <p>И вы получите тариф, который стоит 30$ - бесплатно) </p>
            <br />
            <p>
              <b>Агентство: </b>
            </p>
            <p>Большое спасибо, сейчас попробуем! </p>
            <br />
            <p>
              <b>Менеджер: </b>
            </p>
            <p>Рад вам помочь)</p>
            <p>
              Если вы поделитесь впечатлениями после первого розыгрыша, то это
              будет очень круто!
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
