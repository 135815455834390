import { FC, ReactNode } from "react";
import { Section } from "../Section.tsx";
import s from "./SectionBigTitle.module.scss";

import clsx from "clsx";
import { Typography } from "../../../typography";
type Props = {
  children: ReactNode;
  className?: string;
  largePadding?: boolean;
};
export const SectionBigTitle: FC<Props> = ({
  children,
  className,
  largePadding = false,
}) => {
  return (
    <Section
      className={clsx(s.section, largePadding && s.largePadding, className)}
    >
      <Typography variant={"title_block"} dark>
        {children}
      </Typography>
    </Section>
  );
};
