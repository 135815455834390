import { useState } from "react";
import { SendCode } from "./SendCode.tsx";
import { CheckEmail } from "./CheckEmail.tsx";
import { PATH } from "../../../common/path/path.ts";

export enum RECOVER_STEPS {
  sendCode = 0,
  checkEmail = 1,
}

export const SalesRecoverPass = () => {
  const [step, setStep] = useState<RECOVER_STEPS>(RECOVER_STEPS.sendCode);
  const [email, setEmail] = useState<string>("");
  const onSetStep = (value: RECOVER_STEPS) => setStep(value);
  const onSetEmail = (value: string) => setEmail(value);

  if (step === RECOVER_STEPS.sendCode) {
    return <SendCode onSetStep={onSetStep} onSetEmail={onSetEmail} />;
  } else if (step === RECOVER_STEPS.checkEmail) {
    return (
      <CheckEmail
        email={email}
        text={"Ссылка для изменения пароля отправлена на ваш Email:"}
        pathToLoginPage={`/sales/${PATH.salesLogin}`}
      />
    );
  } else return null;
};
