import Location from "../../../../assets/image/landing/cards/location-tick.svg?react";
import Clock from "../../../../assets/image/landing/cards/clock.svg?react";
import Messages from "../../../../assets/image/landing/cards/messages-2.svg?react";

export const plusesCards = [
  {
    title: "работай\n в любое время",
    desc: "Ты самостоятельно формируешь свой график.",
    img: <Clock />,
  },
  {
    title: "работай\n в любом месте",
    desc: "Ты можешь работать с любого устройства, главное иметь доступ к сети Интернет.",
    img: <Location />,
  },
  {
    title: "общайся с \n членами команды",
    desc: "Мы команда, а значит, мы можем общаться, учиться и развиваться вместе!",
    img: <Messages />,
  },
];
