import { FC } from "react";
import successCopied from "../../../assets/svg/copied.svg";
import s from "./CopiedToClipboard.module.scss";

type PropsType = {
  copiedText: string;
};
export const CopiedToClipboard: FC<PropsType> = ({ copiedText }) => {
  return (
    <div className={s.copiedMessageWrapper}>
      <img src={successCopied} alt="copied" />
      {copiedText}
    </div>
  );
};
