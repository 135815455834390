import { useEffect } from "react";
import { ManagerInfoBlock } from "./managerInfoBlock/ManagerInfoBlock.tsx";
import { ManagerStatsBlock } from "./managerStatsBlock/ManagerStatsBlock.tsx";
import { ManagerClients } from "./clients/ManagerClients.tsx";
import { ManagerTeam } from "./team/ManagerTeam.tsx";
import { publicManagerThunks } from "../../bll/reducers/publicManagerSlice.ts";
import { isFetchingSelector } from "../../selectors/managerPublicSelectors.ts";
import { PreLoader } from "../../common/components/preLoader/PreLoader.tsx";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks.ts";

export const SalesHome = () => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);

  useEffect(() => {
    dispatch(publicManagerThunks.getInfo());
    dispatch(publicManagerThunks.getClients());
    dispatch(publicManagerThunks.getTeam());
  }, []);

  return isFetching ? (
    <PreLoader />
  ) : (
    <>
      <ManagerInfoBlock />
      <ManagerStatsBlock />
      <ManagerClients />
      <ManagerTeam />
    </>
  );
};
