import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChangeUserDataArgs, salesSettingsApi } from "./salesSettingsApi.ts";
import { setAppSuccessMessage } from "../../bll/reducers/appReducer.ts";
import { thunkErrorHandler } from "../../common/utils/thunkErrorHandler.ts";

const slice = createSlice({
  name: "sales/settings",
  initialState: {
    isChangeUserDataLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeUserData.pending, (state) => {
        state.isChangeUserDataLoading = true;
      })
      .addCase(changeUserData.fulfilled, (state) => {
        state.isChangeUserDataLoading = false;
      })
      .addCase(changeUserData.rejected, (state) => {
        state.isChangeUserDataLoading = false;
      });
  },
});

const changeUserData = createAsyncThunk<any, ChangeUserDataArgs>(
  "sales/settings/changeData",
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await salesSettingsApi.changeUserData(arg);
      dispatch(
        setAppSuccessMessage({
          successMessage: "Ваши персональные данные успешно обновлены",
        }),
      );
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  },
);

export const salesSettingsReducer = slice.reducer;
export const salesSettingsActions = slice.actions;
export const salesSettingsThunks = { changeUserData };
