import { FC, PropsWithChildren } from "react";
import s from "./MyPaymentsTable.module.scss";

export const MyPaymentsTableLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th className={s.dateCol}>Дата транзакции</th>
          <th className={s.statusCol}>Статус</th>
          <th className={s.incomeCol}>Сумма</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
