import emailImg from "../../../assets/image/email.svg";
import s from "./CheckEmail.module.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/storeHooks.ts";
import Button from "@mui/material/Button";

export const CheckEmail = () => {
  const navigate = useNavigate();
  const email = useAppSelector((state) => state.salesAuth.managerProfile.email);

  return (
    <div className={s.checkEmailContainer}>
      <div className={s.checkEmailWrapper}>
        <img className={s.checkEmailImg} src={emailImg} alt="check-email" />
        <span className={s.infoMessage}>
          Ссылка для изменения пароля отправлена на ваш email: <b>{email}</b>
        </span>
        <div className={s.button}>
          <Button
            disabled={false}
            variant={"contained"}
            prefix={"main"}
            color={"secondary"}
            onClick={() => navigate("/sales/home")}
          >
            На главную страницу
          </Button>
        </div>
      </div>
    </div>
  );
};
