import List from "@mui/material/List";
import React, { FC, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import burgerIcon from "../../../assets/svg/burger.svg";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/";
import s from "./headerBurger.module.scss";
import { IconClose } from "../../../components/elements-cookies/icon-close";

const theme = createTheme({
  typography: {
    fontSize: Number("28"),
  },
});

export type MenuItemsType = {
  buttonTitle: string;
  buttonAction: () => void;
};

type PropsType = {
  handleLogout: () => void;
  menuItems: MenuItemsType[];
};

export const HeaderBurger: FC<PropsType> = ({ handleLogout, menuItems }) => {
  const [state, setState] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState(open);
    };

  const mappedMenuItems = menuItems.map((item) => (
    <ListItem disablePadding key={item.buttonTitle}>
      <ListItemButton onClick={item.buttonAction} className={s.item}>
        <ListItemText
          primary={item.buttonTitle}
          primaryTypographyProps={{ textAlign: "center" }}
        />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <img style={{ width: "40px" }} src={burgerIcon} alt="burgerIcon" />
      </Button>
      <ThemeProvider theme={theme}>
        <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <IconClose
              handleOnClickIconClose={() => {}}
              sizeIconClose={"small"}
              className={s.close}
            />
            <List>
              {mappedMenuItems}
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout} className={s.item}>
                  <ListItemText
                    primary={"Выход"}
                    primaryTypographyProps={{ textAlign: "center" }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </ThemeProvider>
    </>
  );
};
