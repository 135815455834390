import { useEffect } from "react";
import { Balance } from "./balance/Balance.tsx";
import { BalanceHistory } from "./history/BalanceHistory.tsx";
import { salesBalanceThunks } from "./salesBalanceSlice.ts";
import { PreLoader } from "../../common/components/preLoader/PreLoader.tsx";
import { isBalanceFetchingSelector } from "./salesBalanceSelectors.ts";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks.ts";
import s from "./SalesBalance.module.scss";

export const SalesBalance = () => {
  const dispatch = useAppDispatch();
  const isBalanceFetching = useAppSelector(isBalanceFetchingSelector);

  useEffect(() => {
    dispatch(salesBalanceThunks.getBalance());
  }, [dispatch]);

  if (isBalanceFetching) return <PreLoader />;

  return (
    <div className={s.salesBalanceBlock}>
      <Balance />
      <BalanceHistory />
    </div>
  );
};
