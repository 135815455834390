import { Section } from "../Section.tsx";
import Ellipse from "../../../../assets/image/landing/cards/Ellipse.svg";
import { howWorkCards } from "./howWorkCards.tsx";
import { NavLink } from "react-router-dom";
import { clsx } from "clsx";
import { Green, Typography } from "../../../typography";
import { Button } from "../../../button";
import { PATH } from "../../../../common/path/path.ts";
import { ScrollLink } from "../../../scrollLink/scrollLink.tsx";
import { Card } from "../../../Card/Card.tsx";
import { GetCardDarkStatus } from "../../../../common/utils/getCardDarkStatus.ts";
import { screenSizes } from "../../../../common/const/screenSizes.ts";
import s from "./HowWork.module.scss";

export const HowWork = () => {
  return (
    <Section dark className={s.section}>
      <div className={clsx("container", s.container)}>
        <Typography variant={"title_block"} align={"end"}>
          как это <Green>работает?</Green>
        </Typography>
        <div className={s.content}>
          <div className={s.bgcImg}>
            <img src={Ellipse} alt="" className={s.ellips} />
            <img src={Ellipse} alt="" className={s.ellips} />
          </div>
          <div className={s.cardsContainer}>
            {howWorkCards.map((card, index) => {
              return (
                <Card
                  cardName={"howWork"}
                  key={index}
                  title={card.title}
                  desc={card.desc}
                  dark={GetCardDarkStatus(
                    [1, 2],
                    [1, 3],
                    screenSizes.mediumSmall,
                    index,
                  )}
                  img={card.img}
                />
              );
            })}
          </div>
          <div className={s.bgcImgBottom}>
            <img src={Ellipse} alt="" className={s.ellips} />
          </div>
        </div>
        <div className={s.btnContainer}>
          <Button
            variant={"primary"}
            as={NavLink}
            to={`/sales/${PATH.registration}`}
          >
            получить свой промо
          </Button>
          <ScrollLink to={"faq"}>
            <Button variant={"secondary"}>подробнее</Button>
          </ScrollLink>
        </div>
      </div>
    </Section>
  );
};
